import { mockData as dashboardMockData } from './MockData/dashboard';
import { mockData as attendeesMockData } from './MockData/attendees';
import { mockData as rootMockData } from './MockData/root';
import { mockData as auctionMockData } from './MockData/auction';


export const fetchAuction = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dashboardMockData.auction);
    }, 500); // simulate network delay
  });
};

export const fetchDashboardPledges = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dashboardMockData.pledges);
    }, 500); // simulate network delay
  });
};



export const fetchAllAttendees = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(attendeesMockData.all);
    }, 500); // simulate network delay
  });
};

export const fetchRegistrations = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.registrations);
    }, 500); // simulate network delay
  });
};

export const fetchUsers = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.users);
    }, 500); // simulate network delay
  });
};

export const fetchBidders = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.bidders);
    }, 500); // simulate network delay
  });
};

export const fetchWinners = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(auctionMockData.winners);
    }, 500); // simulate network delay
  });
};

export const fetchPledges = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.pledges);
    }, 500); // simulate network delay
  });
};