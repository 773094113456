import React, { useState, useEffect } from "react";
import "../css/SummaryText.css";
import * as XLSX from "xlsx"; // Import xlsx library
import { fetchTicketData } from '../brendaMockApi'; // Import the mock API function


const SummaryText = () => {
  const [ticketPurchases, setTicketPurchases] = useState([]);

  useEffect(() => {
    const fetchMyTicketData = async () => {
      const data = await fetchTicketData(); // Call the mock API function
      setTicketPurchases(data);
    };

    fetchMyTicketData();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const formatDate = (dateString) => {
    const date = new Date(dateString + "Z");
    const options = {
      timeZone: "America/Los_Angeles", // Pacific Time Zone
      hour12: true, // Use 12-hour clock format
      weekday: "long", // Full day of the week
      year: "numeric", // Full year
      month: "long", // Full month name
      day: "numeric", // Day of the month
      hour: "numeric", // Hour (0-23)
      minute: "numeric", // Minute
      second: "numeric", // Second
    };
    return date.toLocaleString("en-US", options);
  };

  const exportData = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert attendees data to worksheet
    const ws = XLSX.utils.json_to_sheet(ticketPurchases);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Attendees");

    // Generate a unique filename for the Excel file
    const filename = `ticket_sales_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  return (
    <div className="summary-text">
      <p>
        <button onClick={exportData} style={{background:"#fcb61c", fontWeight: "1000"}}>Export Data As Excel</button>
      </p>
      <table>
        <thead>
          <tr>
            <th>Buyer Name</th>
            <th>Purchase Date</th>
            <th>Number of Tickets Purchased</th>
            <th>Total Amount</th>
            <th>Complimentary?</th>
          </tr>
        </thead>
        <tbody>
          {ticketPurchases
            .slice()
            .sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate))
            .map((purchase) => (
              <tr key={purchase.ID}>
                <td>
                  {purchase.firstName} {purchase.lastName}
                </td>
                <td>{formatDate(purchase.CreateDate)}</td>
                <td>{purchase.ticketQty}</td>
                <td>${parseInt(purchase.originalAmount).toFixed(2)}</td>
                <td>{purchase.ticketCost ? "No" : "Yes"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryText;