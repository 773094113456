import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import AdditionalNotes from './AdditionalNotes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx"; // Import xlsx library
import { fetchPledgeCategories } from "../brendaMockApi";
import { fetchPledges } from "../mockApi";

class Pledges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      isLoading: false,
      categories: [],
      expandedRowID: null,
      expandedRowType: null,
      notesText: "",
      receiptInfo: null,
      filterCategories: [
        { name: "All", value: "all" },
        { name: "Paid", value: "paid" },
        { name: "Unpaid", value: "unpaid" }
      ],
      category: "all",
    };
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);

    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  fetchCategories = async () => {
    fetchPledgeCategories()
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            this.setState({ categories: myDataList });
          }
        }
      });
  };

  fetchData = () => {
    fetchPledges()
      .then((dataList) => {

        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];
            myDataList.sort((a, b) => b.Amount - a.Amount);

            this.setState({ searchResults: myDataList });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({isLoading:true}, ()=> {
      this.fetchCategories();
      this.fetchData();
      this.setState({isLoading: false});
    })

  }

  handleDonationChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ dollarAmount: selectedValue, donationType: selectedValue });


    if (selectedValue === "custom") {
      this.setState({ customAmount: "" });
    }
  };

  getIds = () => {
    const idList = this.state.ids
      .split("\n")
      .map((id) => id.trim())
      .filter((id) => id !== "")
      .reverse();

    return idList;
  };

  addPledge = async (id_list) => {
    let amount = 0.0;

    if (id_list.length > 0) {
      if (this.state.donationType === "custom") {
        amount = this.state.customAmount;
      } else {
        amount = this.state.dollarAmount;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/pledges`, {
          method: "POST",
          body: JSON.stringify({
            Identifiers: id_list,
            Amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return true;
      } catch (err) {
        throw new Error("Try submitting pledges again");
      }
    } 
    
    throw new Error("Missing either Paddle Numbers or QR Codes");

  };

  handleDelete = (event, pledgeId) => {
    event.preventDefault();

    toast.warn("Not available in demo mode.");
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    toast.warn("Not available in demo mode.");
  };
  

  convertToPST = (timestampString) => {
    const date = new Date(timestampString);
  
    if (isNaN(date.getTime())) {
      return "Invalid Time";
    }
  
    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;
  
    const pstTime = new Date(date - offsetMilliseconds);
    
    const pstTimeString = pstTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
  
    return pstTimeString;
  };
  

  handlePaid = async (event, bidID) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/pledges`;

    const requestBody = {
      ID: bidID
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Bid paid successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error paying bid", error);
    }
};


updateNotes = async (value) => {
  const stringValue = String(value);
  this.setState({ notesText: stringValue });
};

addNote = async (e, pledge) => {
  e.preventDefault();
  toast.warn("Not available in demo mode.");

  };

  exportData = () => {
    const { searchResults } = this.state;

    // Iterate over each record and add URLs
    const dataWithUrls = searchResults.map(result => {
      let url = "";
      if (result["QR Code"]) {
        url = `https://donate.bigsisters.bc.ca/grapejuicefan?pledgeAmount=${result.amount}&code=${result["QR Code"]}`;
      } else {
        url = `https://donate.bigsisters.bc.ca/grapejuicefan?pledgeAmount=${result.amount}`;
      } 


        return {
            ...result,
            "Payment URL": url // Adding URL as a new column
        };
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert data with URLs to worksheet
    const ws = XLSX.utils.json_to_sheet(dataWithUrls);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Pledges");

    // Generate a unique filename for the Excel file
    const filename = `pledges_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
};


  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

updateExpandRow = (e, gift_ID, type) => {
  e.preventDefault();

  console.log("SCREAM");
  console.log(gift_ID);

  if (this.state.expandedRowID === gift_ID) {
    this.setState({
      expandedRowID: null
    });

    return;
  }

  // Find the item with matching receiptID
  const foundItem = this.state.searchResults.find(
    (item) => item.ID === gift_ID
  );

  console.log(foundItem);

  if (foundItem) {
    // Update receiptInfo with the found item
    this.setState({
      expandedRowID: gift_ID,
      receiptInfo: foundItem,
      expandedRowType: type,
    });
  }
};

  render() {
    return (
      <>
        <LogoHeader />
        <main>
        <section className="container stylization maincont">
            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Categories</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.category}
                        onChange={this.handleOptionChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.filterCategories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p>
                    <button
                      onClick={this.exportData}
                      disabled={this.state.isLoading}
                    >
                      Export Data As Excel
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Add Pledges</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p className="contactform-field contactform-textarea">
                    <label className="contactform-label">
                      Paddle Numbers or QR Codes
                    </label>
                    <span className="contactform-input">
                      <textarea
                        placeholder="Please enter a list of values, such as Paddle Numbers or QR Codes, with each value separated by a newline"
                        name="mess"
                        data-required="text"
                        value={this.state.ids}
                        onChange={(e) => this.setState({ ids: e.target.value })}
                      />
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Pledge Amount</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.donationType}
                        onChange={this.handleDonationChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option or set a default amount
                          &#x2015;
                        </option>
                        {this.state.categories.map(category => (
                            <option key={category.amount} value={category.amount}>
                              {category.name}
                            </option>))}   
                        <option key="custom" value="custom">Custom Amount</option>
                      </Form.Control>
                    </span>
                  </p>

                  {this.state.donationType === "custom" && (
                    <p className="contactform-field contactform-text">
                      <label className="contactform-label"></label>
                      <span className="contactform-input">
                        <input
                          type="text"
                          placeholder="Enter Custom Dollar Amount"
                          value={this.state.customAmount}
                          onChange={(e) =>
                            this.setState({ customAmount: e.target.value })
                          }
                          required // Required only when custom donation type is selected
                        />
                      </span>
                    </p>
                  )}

                  <p className="auth-submit">
                    <input type="submit" value="Add Pledge" />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Pledges</span>
            </h1>
            {this.state.searchResults.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Name</td>
                      <td className="cart-quantity">Amount</td>
                      <td className="cart-quantity">Amount Paid</td>
                      <td className="cart-price">Payment Link</td>
                      <td className="cart-price">Notes</td>

                      <td className="cart-del">&nbsp;</td>
                    <td className="cart-del">&nbsp;</td>
                    <td className="cart-del">&nbsp;</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchResults
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .filter(result => {
                        if (this.state.category === "all") {
                          return true;
                        }
  
                        if (this.state.category === "paid") {
                          if (result.isPaid) {
                            return true;
                          }
                        }
  
                        if (this.state.category === "unpaid") {
                          if (result.isPaid === null) {
                            return true;
                          }
                        }

  
                        return false;
  
                      })
                      .sort(
                        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
                      ) // Sort by the 'Added' field in descending order
                      .map((result, index) => (
                        <>
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.Name}</td>
                          <td className="cart-quantity">
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result.amount)}<br/>
                            <span style={{ color: result["isPaid"] ? '#0ba011' : '#e82131' }}>
                              {result["isPaid"] ? '[Paid]' : '[Not Paid]'}
                            </span>
                          </td>

                          <td className="cart-quantity">
                            {result.amountPaid > 0 && <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result.amountPaid)}</span>}<br/>
                          </td>

                          <td className="cart-ttl">
                            <a style={{color: "#2dccd3"}} href="/" target="_blank" rel="noopener noreferrer">LINK</a></td>

                          <td className="cart-ttl" dangerouslySetInnerHTML={{ __html: result.notes }}></td>

                          <td className="cart-del">
                            <a
                              className="cart-paid"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                toast.warn("Not available in demo mode.");
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-remove"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                toast.warn("Not available in demo mode.");
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>



                          <td className="cart-del">
                            <a
                              className="cart-notes"
                              href="/"
                              onClick={(e) => {
                                this.updateExpandRow(
                                  e,
                                  result.ID,
                                  "Note"
                                );
                              }}
                              role="button"
                            >
                              {"\u00A0"}
                            </a>
                          </td>   

                        </tr>

                      {this.state.expandedRowID === result.ID && (
                        <tr className="notes-row">
                          <td colSpan="9">
                            <>
                              <AdditionalNotes
                                text={this.state.notesText}
                                handleChange={this.updateNotes}
                                addNote={this.addNote}
                                batch={result}
                              />
                            </>

                          </td>
                        </tr>
                      )}
</>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
          <ToastContainer />
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default Pledges;