import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "../css/TagsField.css"; // Create a separate CSS file for styling

class TagsField2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      deleteValue: this.props.existingTags.length > 0 ? this.props.existingTags[0].ID : "",
    };
  }

  createNewTag = async (e) => {
    e.preventDefault();

    const newTag = this.state.inputValue;

  // Check if newTag is empty
  if (!newTag.trim()) {
    // Notify the user that the tag name is empty
    this.props.toast.error("Tag name cannot be empty");
    return;
  }

    try {
      // Assuming tag_name is the name of the new tag to be created
      const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda/tags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Name: newTag }), // Send the tag name in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to create tag");
      }

      // Fetch the updated list of existing tags after creating the new tag
      const data = await response.json();

      this.setState({ inputValue: "" }, () => {
        this.props.toast.success("Added new tag!");
        this.props.fetchExistingTags();
      });
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  deleteTag = async (e) => {
    e.preventDefault();
    let deleteTagID = this.state.deleteValue;

    if (!deleteTagID || deleteTagID === "") {
      deleteTagID = this.props.existingTags[0].ID;
    }

    try {
      // Assuming tag_name is the name of the new tag to be created
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brenda/tags/${deleteTagID}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create tag");
      }

      // Fetch the updated list of existing tags after creating the new tag
      const data = await response.json();

      this.setState({ inputValue: "" }, () => {
        this.props.toast.success("Deleted tag!");
        this.props.fetchExistingTags();
      });
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  handleInputChange = (e) => {
    let newValue = e.target.value;
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleDeleteChange = (e) => {
    let newValue = e.target.value;
    console.log(newValue);
    this.setState({ deleteValue: newValue });
    return newValue;
  };

  getOptions = (myTags) => {
    const cleanedTags = myTags.map((tag) => ({
      value: tag.Name,
      label: tag.Name,
    }));

    return cleanedTags;
  };

  getTagByID = (name) => {
    return this.props.existingTags.find((tag) => tag.Name === name);
  };

  generateTagNames = (tagIds, tags) => {
    console.log(tagIds);
    console.log(tags);
    // Filter the existingTags array to get only the tags whose IDs are present in newItem.Tags
    const matchingTags = tags.filter(tag => tagIds.includes(tag.ID.toString())).map(tag => {
      return {
          ...tag,
          ID: tag.ID.toString()
      };
  });
  
    
    // Extract tag names from matching tags
    const tagNames = matchingTags.map(tag => tag.Name);
    
    // Join tag names with comma and space
    return tagNames.join(", ");
}

  render() {
    const { inputValue } = this.state;
    const { newItem, handleTagUpdate, existingTags } = this.props;

    return (
      <>
        <p className="contactform-field contactform-text">
          <label className="contactform-label">Tags / Categories</label>
          <span className="contactform-input">
            <Form.Control
              id="Tags"
              as="select"
              value={newItem.Tags}
              onChange={handleTagUpdate}
              multiple={true}
            >
              {existingTags.length > 0 && (
                <option key={-1} value={-1}>
                  --------- Clear All Tags ---------
                </option>
              )}
              {existingTags.map((tag) => (
                <option key={tag.ID} value={tag.ID}>
                  {tag.Name}
                </option>
              ))}
            </Form.Control>
            <br/>
            <span style={{fontSize: "15px"}}>Selected Tags: {this.generateTagNames(newItem.Tags, existingTags)}</span>
          </span>
          
        </p>
        <p>
          <label htmlFor="AddTag">Add a Missing Tag</label>
          <div className="input-group">
            <input
              type="text"
              id="AddTag"
              name="AddTag"
              value={inputValue}
              onChange={this.handleInputChange}
              style={{ width: "80%" }}
            />
            <span
              className="input-group-addon addon-button"
              onClick={this.createNewTag}
              style={{ width: "20%" }}
            >
              ADD
            </span>
          </div>
        </p>

        <p className="contactform-field contactform-text" >
          <label className="contactform-label">Delete a Tag</label>
          <span className="contactform-input" style={{ width: "80%", display: "inline-flex" }}>
            <Form.Control
              id="DeleteTags"
              as="select"
              value={existingTags.length > 0 ? existingTags[0] : null}
              onChange={this.handleDeleteChange}
            >
                <option key={-1} value={-1}>
                  --- Choose a tag to delete ---
                </option>

              {existingTags.map((tag) => (
                <option key={tag.ID} value={tag.ID}>
                  {tag.Name}
                </option>
              ))}
            </Form.Control>
            <span
              className="input-group-addon addon-button"
              onClick={this.deleteTag}
              style={{ width: "20%" }}
            >
              DELETE
            </span>
          </span>
        </p>
      </>
    );
  }
}

export default TagsField2;