import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import LogoHeader from "./CheckinLogoHeader";
import "chart.js/auto";
import "../css/Dashboard.css"; // Create a separate CSS file for styling

class Manager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0, // Initial progress value
      amount: 0,
      total_amount: 0,
      progress_data: {
        datalabels: {
          // Configure data labels for circular progress bar
          align: "left",
          text: "We are delighted to announce that the Soiree has received an incredible total of pledges, far exceeding our expectations.",
        },
      },
      pie_data: {},
      options: {},
      screenWidth: 0,
    };
  }

  startProgress = (total) => {
    let interval = setInterval(() => {
      if (this.state.progress < 100) {
        this.setState((prevState) => ({
          progress: prevState.progress + 1,
          amount: (prevState.progress + 1) * total * 0.01,
        }));
      } else {
        clearInterval(interval); // Stop when progress reaches 100%
      }
    }, 70); // Adjust the interval for your needs

    return interval;
  };

  generateComplementaryColors = (count, baseColor) => {
    const colors = [];
    const baseHSL = this.rgbToHsl(baseColor);

    for (let i = 0; i < count; i++) {
      const hue = (baseHSL.h + (i * 180) / count) % 360;
      const hexColor = this.hslToHex({ h: hue, s: baseHSL.s, l: baseHSL.l });
      colors.push(hexColor);
    }

    return colors;
  };

  rgbToHsl = (rgbColor) => {
    const r = rgbColor.r / 255;
    const g = rgbColor.g / 255;
    const b = rgbColor.b / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h: h * 360, s: s, l: l };
  };

  hslToHex = (hslColor) => {
    const { h, s, l } = hslColor;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    const rgb = this.hslToRgb(h, c, x, m);
    return `#${this.rgbToHex(rgb.r)}${this.rgbToHex(rgb.g)}${this.rgbToHex(
      rgb.b
    )}`;
  };

  hslToRgb = (h, c, x, m) => {
    let r, g, b;
    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
    return { r: (r + m) * 255, g: (g + m) * 255, b: (b + m) * 255 };
  };

  rgbToHex = (value) => {
    const hex = Math.round(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);


    // Fetch data for the pie chart
    fetch(`${process.env.REACT_APP_API_URL}/dashboard`)
      .then((response) => response.json())
      .then((data) => {
        const total = data[0]["Count"] + data[1]["Count"];

        const finalFilteredCategories = data.filter(
          (category) =>
            category.Count !== 0 && category.Category !== "Sum"
        );


        const categoryValues = finalFilteredCategories.map(
          (category) => category.Category
        );
        const dataValues = finalFilteredCategories.map(
          (category) => category.Count
        );

        let pie_data_copy = {};
        const baseColor = { r: 255, g: 184, b: 28 };
        let colors_list = this.generateComplementaryColors(
          categoryValues.length,
          baseColor
        );

        pie_data_copy["labels"] = categoryValues;
        pie_data_copy["datasets"] = [];

        pie_data_copy["datasets"].push({
          label: " Count",
          data: dataValues,
          backgroundColor: colors_list,
          hoverBackgroundColor: colors_list,
        });

        const pie_options = {
          tooltip: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const label = dataset.label;
                const value = dataset.data[tooltipItem.index];
                return `${label}: $${value.toFixed(2)}`;
              },
            },
          },
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          // Set the width and height of the chart
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
        };

        this.setState({
          pie_data: pie_data_copy,
          total_amount: total,
          options: pie_options,
        });
      })
      .catch((error) => console.error("Error fetching pie data: ", error));
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  getInspiringQuote = (amount) => {
    const quotes = [
      "When you give, you get so much in return. It's the best investment you can make. — Oprah Winfrey",
      "Every time you have to raise money, it's a reminder that you are doing something important. — Mary Frances Bowley",
      "Fundraising is the gentle art of teaching the joy of giving. — Hank Rosso",
      "The best way to make a difference in the world is to start by making a difference in your own community. — Marian Wright Edelman",
      "Fundraising is the purest form of love. — Nita Lowey",
      "We make a living by what we get, but we make a life by what we give. — Winston Churchill",
      "Don't raise your voice, improve your argument. — Desmond Tutu",
      "To know even one life has breathed easier because you have lived. This is to have succeeded. — Bessie Anderson Stanley",
      "When we give cheerfully and accept gratefully, everyone is blessed. — Maya Angelou",
    ];

    // Map the dollar amount to an index in the quotes array
    const index = Math.floor((amount / 1000000) * quotes.length);

    // Ensure the index is within the bounds of the array
    const quoteIndex = Math.min(Math.max(index, 0), quotes.length - 1);

    // Combine the selected quote
    const inspiringQuote = `${quotes[quoteIndex]}`;

    return inspiringQuote;
  };

  getCongratsMessage = (amount) => {
    // Add a personal congratulations message
    const congratulationsMessage = `Congratulations to Aura Demo for raising $${amount}!`;

    return congratulationsMessage;
  };

  render() {
    const isMobile = this.state.screenWidth <= 768;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Checkin Dashboard</span>
            </h1>
            <div className="auth-wrap">

              <div className="auth-col facts-i">
                <h3 className="facts-i-ttl">Attendees Breakdown by Checkin Status</h3>
                {Object.keys(this.state.pie_data).length > 0 ? ( // Check if data has been received
                  <div
                    id="facts-i-percent-4"
                    data-num="0.7"
                    className="facts-i-percent pie-chart"
                  >
                    <Pie
                      data={this.state.pie_data}
                      options={this.state.options}
                    />
                  </div>
                ) : (
                  <p>Loading pie chart...</p>
                )}
              </div>
            </div>
          </section>



        </main>
      </>
    );
  }
}

export default Manager;
