const ticketData = [
    {
        "billingAddressLine1": "123 Main St",
        "billingCountry": "USA",
        "zip": "12345",
        "lastName": "Doe",
        "billingAddressLine2": "Apt 101",
        "paymentMethodId": "pm_123456789",
        "stripe_amount": "50000",
        "billingProvince": "NY",
        "ticketOption": true,
        "paymentCard": "visa",
        "email": "johndoe@example.com",
        "amountAfterFees": "49000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "John",
        "behalfNote": "",
        "billingCity": "New York",
        "city": "New York City",
        "dietary": "Vegetarian",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 1000.0,
        "ticketCost": true,
        "url_query": "abc123",
        "depositedToBigSisters": "50000",
        "behalf": false,
        "addressLine2": "Suite 202",
        "province": "NY",
        "addressLine1": "456 Elm St",
        "donationAmount": "50000",
        "ID": "b1234567-a123-4567-b123-c1234567890a",
        "ReceiptNumber": "98765",
        "behalfEmail": "",
        "phoneNumber": "5551234567",
        "CreateDate": "2024-03-16T02:23:42.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1500",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "54321",
        "monthly": false,
        "ourFees": "300",
        "organizationName": "Example Organization",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "456 Oak St",
        "billingCountry": "USA",
        "zip": "54321",
        "lastName": "Smith",
        "billingAddressLine2": "Unit B",
        "paymentMethodId": "pm_987654321",
        "stripe_amount": "75000",
        "billingProvince": "CA",
        "ticketOption": true,
        "paymentCard": "mastercard",
        "email": "jsmith@example.com",
        "amountAfterFees": "74000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Jane",
        "behalfNote": "",
        "billingCity": "Los Angeles",
        "city": "Santa Monica",
        "dietary": "Gluten-free",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 1500.0,
        "ticketCost": true,
        "url_query": "def456",
        "depositedToBigSisters": "75000",
        "behalf": false,
        "addressLine2": "Apt 303",
        "province": "CA",
        "addressLine1": "789 Pine Ave",
        "donationAmount": "75000",
        "ID": "c2345678-b234-5678-c234-d234567890bc",
        "ReceiptNumber": "87654",
        "behalfEmail": "",
        "phoneNumber": "5559876543",
        "CreateDate": "2024-04-16T02:23:42.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "2250",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "98765",
        "monthly": false,
        "ourFees": "450",
        "organizationName": "Charity Foundation",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "789 Maple Ave",
        "billingCountry": "USA",
        "zip": "67890",
        "lastName": "Johnson",
        "billingAddressLine2": "",
        "paymentMethodId": "pm_abcdef123",
        "stripe_amount": "30000",
        "billingProvince": "TX",
        "ticketOption": true,
        "paymentCard": "discover",
        "email": "mjohnson@example.com",
        "amountAfterFees": "29000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Michael",
        "behalfNote": "",
        "billingCity": "Houston",
        "city": "Austin",
        "dietary": "None",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 600.0,
        "ticketCost": true,
        "url_query": "ghi789",
        "depositedToBigSisters": "30000",
        "behalf": false,
        "addressLine2": "",
        "province": "TX",
        "addressLine1": "123 Pine St",
        "donationAmount": "30000",
        "ID": "d3456789-c345-6789-d345-e34567890def",
        "ReceiptNumber": "76543",
        "behalfEmail": "",
        "phoneNumber": "5552345678",
        "CreateDate": "2024-04-25T05:23:42.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "900",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "45678",
        "monthly": false,
        "ourFees": "180",
        "organizationName": "Community Support",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "321 Elm St",
        "billingCountry": "USA",
        "zip": "23456",
        "lastName": "Williams",
        "billingAddressLine2": "Suite 100",
        "paymentMethodId": "pm_456789abcdef",
        "stripe_amount": "25000",
        "billingProvince": "FL",
        "ticketOption": true,
        "paymentCard": "visa",
        "email": "awilliams@example.com",
        "amountAfterFees": "24000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Alice",
        "behalfNote": "",
        "billingCity": "Miami",
        "city": "Orlando",
        "dietary": "Vegan",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 500.0,
        "ticketCost": true,
        "url_query": "jkl012",
        "depositedToBigSisters": "25000",
        "behalf": false,
        "addressLine2": "",
        "province": "FL",
        "addressLine1": "987 Oak Ave",
        "donationAmount": "25000",
        "ID": "e4567890-d456-7890-e456-f4567890abcd",
        "ReceiptNumber": "65432",
        "behalfEmail": "",
        "phoneNumber": "5553456789",
        "CreateDate": "2024-05-06T01:12:22.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "750",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "56789",
        "monthly": false,
        "ourFees": "150",
        "organizationName": "Local Charity",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "876 Pine Ave",
        "billingCountry": "USA",
        "zip": "78901",
        "lastName": "Brown",
        "billingAddressLine2": "",
        "paymentMethodId": "pm_abcdef987654",
        "stripe_amount": "40000",
        "billingProvince": "IL",
        "ticketOption": true,
        "paymentCard": "mastercard",
        "email": "cbrown@example.com",
        "amountAfterFees": "39000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Chris",
        "behalfNote": "",
        "billingCity": "Chicago",
        "city": "Springfield",
        "dietary": "Halal",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 800.0,
        "ticketCost": true,
        "url_query": "mno345",
        "depositedToBigSisters": "40000",
        "behalf": false,
        "addressLine2": "",
        "province": "IL",
        "addressLine1": "654 Maple St",
        "donationAmount": "40000",
        "ID": "f5678901-e567-8901-f567-g5678901bcde",
        "ReceiptNumber": "54321",
        "behalfEmail": "",
        "phoneNumber": "5554567890",
        "CreateDate": "2024-05-07T05:34:12.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1200",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "67890",
        "monthly": false,
        "ourFees": "240",
        "organizationName": "Helping Hands",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "543 Oak St",
        "billingCountry": "USA",
        "zip": "89012",
        "lastName": "Martinez",
        "billingAddressLine2": "Suite 300",
        "paymentMethodId": "pm_ghijklmnopqr",
        "stripe_amount": "20000",
        "billingProvince": "AZ",
        "ticketOption": true,
        "paymentCard": "discover",
        "email": "rmartinez@example.com",
        "amountAfterFees": "19000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Rachel",
        "behalfNote": "",
        "billingCity": "Phoenix",
        "city": "Tucson",
        "dietary": "Kosher",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 400.0,
        "ticketCost": true,
        "url_query": "pqr678",
        "depositedToBigSisters": "20000",
        "behalf": false,
        "addressLine2": "",
        "province": "AZ",
        "addressLine1": "210 Elm Ave",
        "donationAmount": "20000",
        "ID": "g6789012-f678-9012-g678-h6789012cdef",
        "ReceiptNumber": "43210",
        "behalfEmail": "",
        "phoneNumber": "5555678901",
        "CreateDate": "2024-05-08T12:45:30.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "600",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "78901",
        "monthly": false,
        "ourFees": "120",
        "organizationName": "Community Outreach",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "210 Pine St",
        "billingCountry": "USA",
        "zip": "90123",
        "lastName": "Garcia",
        "billingAddressLine2": "",
        "paymentMethodId": "pm_qrstuvwx789",
        "stripe_amount": "15000",
        "billingProvince": "WA",
        "ticketOption": true,
        "paymentCard": "visa",
        "email": "dgarcia@example.com",
        "amountAfterFees": "14000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "David",
        "behalfNote": "",
        "billingCity": "Seattle",
        "city": "Bellevue",
        "dietary": "Halal",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 300.0,
        "ticketCost": true,
        "url_query": "xyz789",
        "depositedToBigSisters": "15000",
        "behalf": false,
        "addressLine2": "",
        "province": "WA",
        "addressLine1": "876 Maple Ave",
        "donationAmount": "15000",
        "ID": "h9012345-g901-2345-h901-i9012345defg",
        "ReceiptNumber": "32109",
        "behalfEmail": "",
        "phoneNumber": "5556789012",
        "CreateDate": "2024-05-09T15:00:00.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "450",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "01234",
        "monthly": false,
        "ourFees": "90",
        "organizationName": "Neighborly Help",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "987 Elm St",
        "billingCountry": "USA",
        "zip": "01234",
        "lastName": "Rodriguez",
        "billingAddressLine2": "Suite 400",
        "paymentMethodId": "pm_stuvwxyz123",
        "stripe_amount": "35000",
        "billingProvince": "CO",
        "ticketOption": true,
        "paymentCard": "mastercard",
        "email": "mrodriguez@example.com",
        "amountAfterFees": "34000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Maria",
        "behalfNote": "",
        "billingCity": "Denver",
        "city": "Boulder",
        "dietary": "Vegetarian",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 700.0,
        "ticketCost": true,
        "url_query": "456xyz",
        "depositedToBigSisters": "35000",
        "behalf": false,
        "addressLine2": "",
        "province": "CO",
        "addressLine1": "543 Maple St",
        "donationAmount": "35000",
        "ID": "i0123456-h012-3456-i012-j0123456efgh",
        "ReceiptNumber": "21098",
        "behalfEmail": "",
        "phoneNumber": "5557890123",
        "CreateDate": "2024-07-05T01:04:13.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1050",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "12340",
        "monthly": false,
        "ourFees": "210",
        "organizationName": "Helping Hands Foundation",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "654 Oak Ave",
        "billingCountry": "USA",
        "zip": "34567",
        "lastName": "Lopez",
        "billingAddressLine2": "",
        "paymentMethodId": "pm_234567890xyz",
        "stripe_amount": "60000",
        "billingProvince": "GA",
        "ticketOption": true,
        "paymentCard": "visa",
        "email": "jlopez@example.com",
        "amountAfterFees": "59000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Jose",
        "behalfNote": "",
        "billingCity": "Atlanta",
        "city": "Savannah",
        "dietary": "None",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 1200.0,
        "ticketCost": true,
        "url_query": "789abc",
        "depositedToBigSisters": "60000",
        "behalf": false,
        "addressLine2": "",
        "province": "GA",
        "addressLine1": "321 Pine St",
        "donationAmount": "60000",
        "ID": "j1234567-i234-5678-j234-k234567890ab",
        "ReceiptNumber": "09876",
        "behalfEmail": "",
        "phoneNumber": "5558901234",
        "CreateDate": "2024-07-07T11:12:02.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1800",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "23456",
        "monthly": false,
        "ourFees": "360",
        "organizationName": "Community Support Network",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "321 Maple St",
        "billingCountry": "USA",
        "zip": "45678",
        "lastName": "Lee",
        "billingAddressLine2": "Suite 200",
        "paymentMethodId": "pm_34567890abc",
        "stripe_amount": "45000",
        "billingProvince": "NJ",
        "ticketOption": true,
        "paymentCard": "amex",
        "email": "jlee@example.com",
        "amountAfterFees": "44000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Jessica",
        "behalfNote": "",
        "billingCity": "Jersey City",
        "city": "Newark",
        "dietary": "Gluten-free",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 900.0,
        "ticketCost": true,
        "url_query": "lmn901",
        "depositedToBigSisters": "45000",
        "behalf": false,
        "addressLine2": "",
        "province": "NJ",
        "addressLine1": "876 Oak Ave",
        "donationAmount": "45000",
        "ID": "k2345678-j345-6789-k234-l234567890cd",
        "ReceiptNumber": "98765",
        "behalfEmail": "",
        "phoneNumber": "5559012345",
        "CreateDate": "2024-07-21T16:14:12.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1350",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "34567",
        "monthly": false,
        "ourFees": "270",
        "organizationName": "Supportive Community",
        "tribute": false,
        "payFees": true
    },
    {
        "billingAddressLine1": "789 Elm Ave",
        "billingCountry": "USA",
        "zip": "56789",
        "lastName": "Clark",
        "billingAddressLine2": "",
        "paymentMethodId": "pm_678901234def",
        "stripe_amount": "55000",
        "billingProvince": "MA",
        "ticketOption": true,
        "paymentCard": "visa",
        "email": "cclark@example.com",
        "amountAfterFees": "54000",
        "country": "USA",
        "behalfPhoneNumber": "",
        "firstName": "Christopher",
        "behalfNote": "",
        "billingCity": "Boston",
        "city": "Cambridge",
        "dietary": "Vegetarian",
        "anonymous": false,
        "behalfFirstName": "",
        "originalAmount": 1100.0,
        "ticketCost": true,
        "url_query": "opq123",
        "depositedToBigSisters": "55000",
        "behalf": false,
        "addressLine2": "",
        "province": "MA",
        "addressLine1": "234 Oak St",
        "donationAmount": "55000",
        "ID": "l3456789-k456-7890-l345-m34567890def",
        "ReceiptNumber": "87654",
        "behalfEmail": "",
        "phoneNumber": "5550123456",
        "CreateDate": "2024-08-12T02:44:12.442051",
        "ticketQty": "1",
        "billingAddress": true,
        "originURL": "https://example.com/event",
        "attendEvent": true,
        "stripeFees": "1650",
        "organization": true,
        "behalfLastName": "",
        "billingZip": "45678",
        "monthly": false,
        "ourFees": "330",
        "organizationName": "Local Support Group",
        "tribute": false,
        "payFees": true
    }
];

const ticketsTotal = {"Total":88000.0};

const mockData = {ticketData, ticketsTotal};

  
  export default mockData;
  