// src/utils/mockApi.js
import mockData from './MockData/brenda/ticket-data';
import {mockData as rootMockData} from './MockData/brenda/root';

export const fetchTicketData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData.ticketData);
    }, 500); // simulate network delay
  });
};

export const fetchTicketTotal = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData.ticketsTotal);
    }, 500); // simulate network delay
  });
};

export const fetchPledgeCategories = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.pledgeCategories);
    }, 500); // simulate network delay
  });
};

export const fetchAuctionitems = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(rootMockData.auctionItems);
    }, 500); // simulate network delay
  });
};