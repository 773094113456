const winners = [
    {
        "id": 83,
        "bid_id": 60,
        "item_id": 106,
        "nickname": "John D",
        "email": "john.doe@example.com",
        "user_id": "1cadb508-c041-70a5-e789-a610540e259f",
        "bid_amount": 65.0,
        "timestamp": "2024-04-09 00:00:00",
        "item_description": "Brancaia Grappa, Tuscany, Italy",
        "hasPaid": 1,
        "address_line_1": "1234 Elm Street",
        "address_line_2": null,
        "city": "Springfield",
        "province": "IL",
        "postal_code": "62701",
        "customer_id": 119,
        "FirstName": "John",
        "LastName": "Doe",
        "stripe_id": "cus_A1B2C3D4E5F6G7",
        "phone_number": "+15551234567"
    },
    {
        "id": 84,
        "bid_id": 272,
        "item_id": 117,
        "nickname": "Jane S",
        "email": "jane.smith@example.com",
        "user_id": "0c8d5548-d031-7029-6ca7-b741e6892003",
        "bid_amount": 65.0,
        "timestamp": "2024-04-11 19:33:00",
        "item_description": "1985 William Hill Reserve Cabernet Sauvignon",
        "hasPaid": 0,
        "address_line_1": "5678 Oak Street",
        "address_line_2": null,
        "city": "Shelbyville",
        "province": "IL",
        "postal_code": "62565",
        "customer_id": 274,
        "FirstName": "Jane",
        "LastName": "Smith",
        "stripe_id": "cus_H8I9J0K1L2M3N4",
        "phone_number": "+15555678901"
    },
    {
        "id": 85,
        "bid_id": 245,
        "item_id": 113,
        "nickname": "Alice J",
        "email": "alice.johnson@example.com",
        "user_id": "ec2db5c8-0051-707c-551e-1672f5f9b79c",
        "bid_amount": 70.0,
        "timestamp": "2024-04-11 00:00:00",
        "item_description": "Legacy Collection Mission Hill Quatrain 2018",
        "hasPaid": 1,
        "address_line_1": "8765 Pine Street",
        "address_line_2": null,
        "city": "Ogdenville",
        "province": "IL",
        "postal_code": "62025",
        "customer_id": 222,
        "FirstName": "Alice",
        "LastName": "Johnson",
        "stripe_id": "cus_O5P6Q7R8S9T0U1",
        "phone_number": "+15558765432"
    },
    {
        "id": 86,
        "bid_id": 88,
        "item_id": 110,
        "nickname": "Bob B",
        "email": "bob.brown@example.com",
        "user_id": "f1dbf6c8-d002-707b-231e-1678g7h9i0j1",
        "bid_amount": 75.0,
        "timestamp": "2024-04-12 15:45:00",
        "item_description": "Vintage Port, Portugal 1995",
        "hasPaid": 1,
        "address_line_1": "2345 Maple Street",
        "address_line_2": null,
        "city": "Capital City",
        "province": "IL",
        "postal_code": "62702",
        "customer_id": 223,
        "FirstName": "Bob",
        "LastName": "Brown",
        "stripe_id": "cus_V2W3X4Y5Z6A7B8",
        "phone_number": "+15552345678"
    },
    {
        "id": 87,
        "bid_id": 156,
        "item_id": 115,
        "nickname": "Carol D",
        "email": "carol.davis@example.com",
        "user_id": "g2ecf6d9-e003-707c-442f-1789h8i0j2k3",
        "bid_amount": 80.0,
        "timestamp": "2024-04-13 10:20:00",
        "item_description": "Chateau Margaux 2010",
        "hasPaid": 1,
        "address_line_1": "3456 Birch Street",
        "address_line_2": null,
        "city": "North Haverbrook",
        "province": "IL",
        "postal_code": "62564",
        "customer_id": 224,
        "FirstName": "Carol",
        "LastName": "Davis",
        "stripe_id": "cus_C9D8E7F6G5H4I3",
        "phone_number": "+15553456789"
    },
    {
        "id": 88,
        "bid_id": 189,
        "item_id": 119,
        "nickname": "David M",
        "email": "david.martinez@example.com",
        "user_id": "h3fdg7e0-f004-808d-553g-1890i9j1k4l5",
        "bid_amount": 85.0,
        "timestamp": "2024-04-14 14:30:00",
        "item_description": "Dom Perignon 2008",
        "hasPaid": 0,
        "address_line_1": "4567 Cedar Street",
        "address_line_2": null,
        "city": "Springfield",
        "province": "IL",
        "postal_code": "62703",
        "customer_id": 225,
        "FirstName": "David",
        "LastName": "Martinez",
        "stripe_id": "cus_J2K1L0M9N8O7P6",
        "phone_number": "+15554567890"
    },
    {
        "id": 89,
        "bid_id": 222,
        "item_id": 120,
        "nickname": "Emma L",
        "email": "emma.lopez@example.com",
        "user_id": "i4fgh8h1-g005-909e-664h-1901j2k3l5m6",
        "bid_amount": 90.0,
        "timestamp": "2024-04-15 17:55:00",
        "item_description": "Screaming Eagle 2012",
        "hasPaid": 1,
        "address_line_1": "5678 Redwood Street",
        "address_line_2": null,
        "city": "Shelbyville",
        "province": "IL",
        "postal_code": "62565",
        "customer_id": 226,
        "FirstName": "Emma",
        "LastName": "Lopez",
        "stripe_id": "cus_Q3R2S1T0U9V8W7",
        "phone_number": "+15555678901"
    },
    {
        "id": 90,
        "bid_id": 248,
        "item_id": 121,
        "nickname": "Frank W",
        "email": "frank.wilson@example.com",
        "user_id": "j5ghi9i2-h006-101f-775i-2012k3l4m5n7",
        "bid_amount": 95.0,
        "timestamp": "2024-04-16 19:10:00",
        "item_description": "Penfolds Grange 2015",
        "hasPaid": 1,
        "address_line_1": "6789 Spruce Street",
        "address_line_2": null,
        "city": "Ogdenville",
        "province": "IL",
        "postal_code": "62025",
        "customer_id": 227,
        "FirstName": "Frank",
        "LastName": "Wilson",
        "stripe_id": "cus_X4Y3Z2A1B0C9D8",
        "phone_number": "+15556789012"
    },
    {
        "id": 91,
        "bid_id": 273,
        "item_id": 122,
        "nickname": "Grace A",
        "email": "grace.anderson@example.com",
        "user_id": "k6hij0j3-i007-202g-886j-2123l4m5n6o8",
        "bid_amount": 100.0,
        "timestamp": "2024-04-17 21:25:00",
        "item_description": "Chateau Latour 2005",
        "hasPaid": 1,
        "address_line_1": "7890 Chestnut Street",
        "address_line_2": null,
        "city": "Capital City",
        "province": "IL",
        "postal_code": "62702",
        "customer_id": 228,
        "FirstName": "Grace",
        "LastName": "Anderson",
        "stripe_id": "cus_E5F4G3H2I1J0K9",
        "phone_number": "+15557890123"
    },
    {
        "id": 92,
        "bid_id": 299,
        "item_id": 123,
        "nickname": "Henry T",
        "email": "henry.thomas@example.com",
        "user_id": "l7ijk1k4-j008-303h-997k-2234m5n6o7p9",
        "bid_amount": 105.0,
        "timestamp": "2024-04-18 23:40:00",
        "item_description": "Krug Vintage 2004",
        "hasPaid": 0,
        "address_line_1": "8901 Aspen Street",
        "address_line_2": null,
        "city": "North Haverbrook",
        "province": "IL",
        "postal_code": "62564",
        "customer_id": 229,
        "FirstName": "Henry",
        "LastName": "Thomas",
        "stripe_id": "cus_M6N5O4P3Q2R1S0",
        "phone_number": "+15558901234"
    }
];



export const mockData = {winners};
