import React from "react";
import logo from "../img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { faPerson } from "@fortawesome/free-solid-svg-icons";

function LogoHeader() {

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Aura Demo" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          <li>
              <a href="/grapejuice2024/dashboard">
              <FontAwesomeIcon icon={faChartBar} />&nbsp;
                <span className="shop-menu-ttl">Dashboard</span>
              </a>
            </li>
            <li>
              <a href="/grapejuice2024/attendees">
              <FontAwesomeIcon icon={faPerson} />&nbsp;
                <span className="shop-menu-ttl">Register Tickets</span>
              </a>
            </li>
            <li>
              <a href="/grapejuice2024/auction-registration">
              <FontAwesomeIcon icon={faPerson} />&nbsp;
                <span className="shop-menu-ttl">Register Bidders</span>
              </a>
            </li>
            <li>
              <a href="/grapejuice2024/auction-results">
              <FontAwesomeIcon icon={faHammer} />&nbsp;
                <span className="shop-menu-ttl">Auction Results</span>
              </a>
            </li>
            <li>
              <a href="/grapejuice2024/auction">
              <FontAwesomeIcon icon={faHammer} />&nbsp;
                <span className="shop-menu-ttl">Auction</span>
              </a>
            </li>

            <li>
            <a href="/grapejuice2024/pledges">
              <FontAwesomeIcon icon={faDisplay} />&nbsp;
                <span className="shop-menu-ttl">FAN</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader;