const auction = [
    {
        "Category": "Sum of Paid Auction Bids",
        "Count": 25000.0
    },
    {
        "Category": "Sum of Unpaid Auction Bids",
        "Count": 750.0
    }
];

const pledges = [
    {
        "Category": "Sum of Paid Pledges",
        "Count": 48000.0
    },
    {
        "Category": "Sum of Unpaid Pledges",
        "Count": 49000.0
    }
];

export const mockData = { auction, pledges };
