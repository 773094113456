const registrations = [
    {
        "attendee_id": 1,
        "purchase_reference": "pi_1234567890",
        "attendee_firstname": "Alice",
        "attendee_lastname": "Johnson",
        "email": "alice.johnson@example.com",
        "attendee_phone_number": "123-456-7890",
        "address_line_1": "123 Maple Street",
        "address_line_2": "Apt 101",
        "city": "Springfield",
        "province": "IL",
        "postal_code": "62701",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE123",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 2,
        "purchase_reference": "pi_2345678901",
        "attendee_firstname": "Bob",
        "attendee_lastname": "Smith",
        "email": "bob.smith@example.com",
        "attendee_phone_number": "234-567-8901",
        "address_line_1": "456 Oak Street",
        "address_line_2": "",
        "city": "Greenville",
        "province": "SC",
        "postal_code": "29601",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE456",
        "appleWalletStatus": "PASS_ISSUED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 3,
        "purchase_reference": "pi_3456789012",
        "attendee_firstname": "Emily",
        "attendee_lastname": "Davis",
        "email": "emily.davis@example.com",
        "attendee_phone_number": "345-678-9012",
        "address_line_1": "789 Pine Street",
        "address_line_2": "Suite 200",
        "city": "Boulder",
        "province": "CO",
        "postal_code": "80301",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE789",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 4,
        "purchase_reference": "pi_4567890123",
        "attendee_firstname": "David",
        "attendee_lastname": "Brown",
        "email": "david.brown@example.com",
        "attendee_phone_number": "456-789-0123",
        "address_line_1": "321 Cedar Street",
        "address_line_2": "Apt 50",
        "city": "Austin",
        "province": "TX",
        "postal_code": "78701",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE012",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 5,
        "purchase_reference": "pi_5678901234",
        "attendee_firstname": "Grace",
        "attendee_lastname": "Martinez",
        "email": "grace.martinez@example.com",
        "attendee_phone_number": "567-890-1234",
        "address_line_1": "876 Elm Street",
        "address_line_2": "",
        "city": "Seattle",
        "province": "WA",
        "postal_code": "98101",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE567",
        "appleWalletStatus": "PASS_ISSUED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 6,
        "purchase_reference": "pi_6789012345",
        "attendee_firstname": "Oliver",
        "attendee_lastname": "Garcia",
        "email": "oliver.garcia@example.com",
        "attendee_phone_number": "678-901-2345",
        "address_line_1": "543 Birch Street",
        "address_line_2": "Apt 10",
        "city": "Portland",
        "province": "OR",
        "postal_code": "97201",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE890",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 7,
        "purchase_reference": "pi_7890123456",
        "attendee_firstname": "Sophia",
        "attendee_lastname": "Lopez",
        "email": "sophia.lopez@example.com",
        "attendee_phone_number": "789-012-3456",
        "address_line_1": "987 Walnut Street",
        "address_line_2": "Suite 300",
        "city": "San Francisco",
        "province": "CA",
        "postal_code": "94101",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE123",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 8,
        "purchase_reference": "pi_8901234567",
        "attendee_firstname": "Lucas",
        "attendee_lastname": "Martinez",
        "email": "lucas.martinez@example.com",
        "attendee_phone_number": "890-123-4567",
        "address_line_1": "765 Pine Street",
        "address_line_2": "",
        "city": "Denver",
        "province": "CO",
        "postal_code": "80201",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE456",
        "appleWalletStatus": "PASS_ISSUED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 9,
        "purchase_reference": "pi_9012345678",
        "attendee_firstname": "Ella",
        "attendee_lastname": "Brown",
        "email": "ella.brown@example.com",
        "attendee_phone_number": "901-234-5678",
        "address_line_1": "543 Cedar Street",
        "address_line_2": "Apt 20",
        "city": "Houston",
        "province": "TX",
        "postal_code": "77001",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE789",
        "appleWalletStatus": "PASS_INSTALLED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    },
    {
        "attendee_id": 10,
        "purchase_reference": "pi_0123456789",
        "attendee_firstname": "Michael",
        "attendee_lastname": "Garcia",
        "email": "michael.garcia@example.com",
        "attendee_phone_number": "012-345-6789",
        "address_line_1": "876 Maple Street",
        "address_line_2": "",
        "city": "Phoenix",
        "province": "AZ",
        "postal_code": "85001",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "barcode": "BARCODE012",
        "appleWalletStatus": "PASS_ISSUED",
        "registration_id": null,
        "url_query": null,
        "registration_phone_number": null,
        "dietary": null,
        "replacement_attendee_id": null,
        "original_attendee_id": null,
        "original_attendee_name": null
    }
];

const users = {
    "all_users_details": [
        {
            "email": "alice.johnson@example.com",
            "email_verified": "true",
            "family_name": "Johnson",
            "given_name": "Alice",
            "sub": "12345678-abcd-1234-efgh-abcdefghijkl",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Alice J",
            "phone_number": "5551234567"
        },
        {
            "email": "bob.smith@example.com",
            "email_verified": "true",
            "family_name": "Smith",
            "given_name": "Bob",
            "sub": "23456789-bcde-2345-fghi-bcdefghijklm",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Bob S",
            "phone_number": "5552345678"
        },
        {
            "email": "emily.davis@example.com",
            "email_verified": "true",
            "family_name": "Davis",
            "given_name": "Emily",
            "sub": "34567890-cdef-3456-ghij-cdefghijklmn",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Emily D",
            "phone_number": "5553456789"
        },
        {
            "email": "david.brown@example.com",
            "email_verified": "true",
            "family_name": "Brown",
            "given_name": "David",
            "sub": "45678901-defg-4567-hijk-abcdefghijklmnop",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "Admin"
            ],
            "nickname": "David B",
            "phone_number": "5554567890"
        },
        {
            "email": "grace.martinez@example.com",
            "email_verified": "true",
            "family_name": "Martinez",
            "given_name": "Grace",
            "sub": "56789012-efgh-5678-ijkl-mnopqrstuvwx",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Grace M",
            "phone_number": "5555678901"
        },
        {
            "email": "oliver.garcia@example.com",
            "email_verified": "true",
            "family_name": "Garcia",
            "given_name": "Oliver",
            "sub": "67890123-fghi-6789-jklm-nopqrstuvwxyz",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Garcia O",
            "phone_number": "5556789012"
        },
        {
            "email": "sophia.lopez@example.com",
            "email_verified": "true",
            "family_name": "Lopez",
            "given_name": "Sophia",
            "sub": "78901234-ghij-7890-klmn-opqrstuvwxy",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "Admin"
            ],
            "nickname": "Sophia L",
            "phone_number": "5557890123"
        },
        {
            "email": "lucas.martinez@example.com",
            "email_verified": "true",
            "family_name": "Martinez",
            "given_name": "Lucas",
            "sub": "89012345-hijk-8901-lmno-pqrstuvwxyzabc",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Lucas M",
            "phone_number": "5558901234"
        },
        {
            "email": "ella.brown@example.com",
            "email_verified": "true",
            "family_name": "Brown",
            "given_name": "Ella",
            "sub": "90123456-ijkl-9012-mnop-qrstuvwxyzabcd",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Ella B",
            "phone_number": "5559012345"
        },
        {
            "email": "michael.garcia@example.com",
            "email_verified": "true",
            "family_name": "Garcia",
            "given_name": "Michael",
            "sub": "01234567-klmn-0123-opqr-stuvwxyzabcdef",
            "ConfirmationStatus": "CONFIRMED",
            "Status": true,
            "Groups": [
                "User"
            ],
            "nickname": "Michael G",
            "phone_number": "5550123456"
        }
    ]
};


const bidders = [
    {
        "ID": 1,
        "code": "B1234",
        "attendee_id": 1,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Alice",
        "voucher_email": "alice.johnson@example.com",
        "nickname": "Test"
    },
    {
        "ID": 2,
        "code": "C2345",
        "attendee_id": 2,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Bob",
        "voucher_email": "bob.smith@example.com"
    },
    {
        "ID": 3,
        "code": "D3456",
        "attendee_id": 3,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Emily",
        "voucher_email": "emily.davis@example.com"
    },
    {
        "ID": 4,
        "code": "E4567",
        "attendee_id": 4,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "David",
        "voucher_email": "david.brown@example.com"
    },
    {
        "ID": 5,
        "code": "F5678",
        "attendee_id": 5,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Grace",
        "voucher_email": "grace.martinez@example.com"
    },
    {
        "ID": 6,
        "code": "G6789",
        "attendee_id": 6,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Oliver",
        "voucher_email": "oliver.garcia@example.com"
    },
    {
        "ID": 7,
        "code": "H7890",
        "attendee_id": 7,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Sophia",
        "voucher_email": "sophia.lopez@example.com"
    },
    {
        "ID": 8,
        "code": "I8901",
        "attendee_id": 8,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Lucas",
        "voucher_email": "lucas.martinez@example.com"
    },
    {
        "ID": 9,
        "code": "J9012",
        "attendee_id": 9,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Ella",
        "voucher_email": "ella.brown@example.com"
    },
    {
        "ID": 10,
        "code": "K0123",
        "attendee_id": 10,
        "alreadyUsed": 0,
        "purchase_reference": null,
        "attendee_firstname": null,
        "attendee_lastname": null,
        "email": null,
        "phone_number": null,
        "address_line_1": null,
        "address_line_2": null,
        "city": null,
        "province": null,
        "postal_code": null,
        "country": null,
        "voucher_salutation": "Michael",
        "voucher_email": "michael.garcia@example.com"
    }
];

const pledges = [
    {
        "ID": 764,
        "amount": 2000.0,
        "amountPaid": 1000.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:00:00",
        "attendeeID": 1001,
        "isDeleted": 0,
        "isPaid": "1",
        "notes": null,
        "Paddle Number": 123,
        "QR Code": "abc123",
        "Name": "John Doe",
        "Phone Number": "123-456-7890",
        "Email": "john.doe@example.com"
    },
    {
        "ID": 765,
        "amount": 3000.0,
        "amountPaid": 0.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:01:00",
        "attendeeID": 1002,
        "isDeleted": 0,
        "isPaid": null,
        "notes": null,
        "Paddle Number": null,
        "QR Code": null,
        "Name": "Jane Smith",
        "Phone Number": "234-567-8901",
        "Email": "jane.smith@example.com"
    },
    {
        "ID": 766,
        "amount": 5000.0,
        "amountPaid": 5000.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:02:00",
        "attendeeID": 1003,
        "isDeleted": 0,
        "isPaid": "1",
        "notes": "Received full payment",
        "Paddle Number": 456,
        "QR Code": "def456",
        "Name": "Michael Johnson",
        "Phone Number": "345-678-9012",
        "Email": "michael.johnson@example.com"
    },
    {
        "ID": 767,
        "amount": 2500.0,
        "amountPaid": 0.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:03:00",
        "attendeeID": 1004,
        "isDeleted": 0,
        "isPaid": null,
        "notes": null,
        "Paddle Number": null,
        "QR Code": null,
        "Name": "Sarah Brown",
        "Phone Number": "456-789-0123",
        "Email": "sarah.brown@example.com"
    },
    {
        "ID": 768,
        "amount": 1500.0,
        "amountPaid": 0.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:04:00",
        "attendeeID": 1005,
        "isDeleted": 0,
        "isPaid": null,
        "notes": null,
        "Paddle Number": null,
        "QR Code": null,
        "Name": "Alex Davis",
        "Phone Number": "567-890-1234",
        "Email": "alex.davis@example.com"
    },
    {
        "ID": 769,
        "amount": 4000.0,
        "amountPaid": 2000.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:05:00",
        "attendeeID": 1006,
        "isDeleted": 0,
        "isPaid": "1",
        "notes": "Partial payment received",
        "Paddle Number": 789,
        "QR Code": "ghi789",
        "Name": "Emily White",
        "Phone Number": "678-901-2345",
        "Email": "emily.white@example.com"
    },
    {
        "ID": 770,
        "amount": 6000.0,
        "amountPaid": 0.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:06:00",
        "attendeeID": 1007,
        "isDeleted": 0,
        "isPaid": null,
        "notes": null,
        "Paddle Number": null,
        "QR Code": null,
        "Name": "James Miller",
        "Phone Number": "789-012-3456",
        "Email": "james.miller@example.com"
    },
    {
        "ID": 771,
        "amount": 10000.0,
        "amountPaid": 10000.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:07:00",
        "attendeeID": 1008,
        "isDeleted": 0,
        "isPaid": "1",
        "notes": "Fully paid upfront",
        "Paddle Number": 987,
        "QR Code": "jkl987",
        "Name": "Sophia Lee",
        "Phone Number": "890-123-4567",
        "Email": "sophia.lee@example.com"
    },
    {
        "ID": 772,
        "amount": 3500.0,
        "amountPaid": 0.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:08:00",
        "attendeeID": 1009,
        "isDeleted": 0,
        "isPaid": null,
        "notes": null,
        "Paddle Number": null,
        "QR Code": null,
        "Name": "Olivia Taylor",
        "Phone Number": "901-234-5678",
        "Email": "olivia.taylor@example.com"
    },
    {
        "ID": 773,
        "amount": 4500.0,
        "amountPaid": 3000.0,
        "isSent": 0,
        "dateAdded": "2024-07-04 10:09:00",
        "attendeeID": 1010,
        "isDeleted": 0,
        "isPaid": "1",
        "notes": "Received partial payment",
        "Paddle Number": 654,
        "QR Code": "mno654",
        "Name": "Andrew Clark",
        "Phone Number": "012-345-6789",
        "Email": "andrew.clark@example.com"
    }
];




export const mockData = {registrations, users, bidders, pledges};
