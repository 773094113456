import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import * as XLSX from "xlsx"; // Import xlsx library
import Select from "react-select"; // Import Select from react-select
import { fetchWinners } from "../mockApi";

const SEARCH_DROPDOWN_STYLE = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      borderWidth: "0px",
      minHeight: "0px",
      boxShadow: "0px",
    };
  },
  menu: (baseStyles, state) => {
    return {
      ...baseStyles,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px",
      fontSize: "0.9rem",
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: state.isFocused ? "#DEEBFF" : "white",
      color: "black",
    };
  },
};

class AuctionResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAttendee: null,
      attendees: [],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      intervalId: null, // Store the interval ID in state
      categories: [
        { name: "All", value: "all" },
        { name: "Paid", value: "paid" },
        { name: "Not Yet Paid", value: "unpaid" },
      ],
      category: "all",
      search: ""
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.fetchAttendees();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async fetchAttendees() {
    this.setState({isLoading: true});
    try {
      let data = await fetchWinners();
      this.setState({ attendees: data, isLoading: false });
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  getAttendeeOptions = () => {
    const results= this.state.attendees.map((attendee) => ({
      value: attendee.attendee_id, // Use "attendee_id" instead of "id"
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`, // Update property names
    }));

    console.log(results);
    return results;
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  downloadPDF = (url, filename) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    clearInterval(this.state.intervalId);
    this.setState({ isLoading: false });
  };

  checkPDF = (filename) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/brenda/ticket-download/${filename}`,
    }).then((response) => {
      const result = response.data;
      console.log(result);

      if (result["url"] && result["url"] !== "") {
        console.log("Downloading URL Now!");
        this.downloadPDF(result["url"], filename);
      } else {
        // If the url is empty, continue checking
        console.log("URL is empty. Will check again in 20 seconds.");
      }
    });
  };

  triggerDownloadSingleTicket = (e, data) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const payload = {
      attendee_id: data["attendee_id"],
      firstname: data["attendee_firstname"],
      lastname: data["attendee_lastname"],
      barcode: data["barcode"],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/brenda/trigger-ticket`,
      data: payload, // Include payload in the request body
    }).then((response) => {
      const result = response.data;
      console.log(result);

      const startCheckPDFInterval = () => {
        const intervalId = setInterval(() => {
          this.checkPDF(result["filename"]);
        }, 5000);
        this.setState({ intervalId });
      };

      // Call the function that starts the interval
      startCheckPDFInterval();
    });
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };


  resendSingleTicket = (e, data) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const payload = {
      attendee_id: data["attendee_id"],
      firstname: data["attendee_firstname"],
      lastname: data["attendee_lastname"],
      barcode: data["barcode"],
      email: data["email"],
      phoneNumber: data["attendee_phone_number"],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/brenda/reissue-ticket`,
      data: payload, // Include payload in the request body
    }).then((response) => {
      const result = response.data;
      console.log(result);
      toast.success("Tickets Reissued!");
      this.setState({ isLoading: false });
    });
  };

  exportData = (e) => {
    e.preventDefault();
    const { attendees } = this.state;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert attendees data to worksheet
    const ws = XLSX.utils.json_to_sheet(attendees);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Attendees");

    // Generate a unique filename for the Excel file
    const filename = `auction_winners_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  render() {
    const { attendees, screenWidth } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
        <section className="container stylization maincont">
            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Categories</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.category}
                        onChange={this.handleOptionChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p>
                    <button
                      onClick={this.exportData}
                      disabled={this.state.isLoading}
                    >
                      Export Data As Excel
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Auction Winners</span>
            </h1>



            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-quantity">Item</td>
                    <td className="cart-quantity">Contact</td>
                    <td className="cart-quantity">Winning Bid Amount</td>
                    <td className="cart-quantity">Has Paid?</td>
                  </tr>
                </thead>
                <tbody>
                  {attendees
                    .slice()
                    .filter(result => {
                      if (this.state.category === "all") {
                        return true;
                      }

                      if (this.state.category === "paid") {
                        if (result.hasPaid === 1) {
                          return true;
                        }
                      }

                      if (this.state.category === "unpaid") {
                        if (result.hasPaid === 0) {
                          return true;
                        }
                      }

                      return false;

                    })
                    .sort((a, b) => b.id - a.id) // Sort attendees by attendee_id in descending order
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-quantity">
                        [{result["item_id"]}]<br/>
                          {result["item_description"]}
                        </td>
                        <td className="cart-quantity">
                          {result["FirstName"]}{" "}
                          {result["LastName"]}<br />
                          {result["address_line_1"]}
                          <br />
                          {result["address_line_2"] && (
                            <>
                              {result["address_line_2"]}
                              <br />
                            </>
                          )}
                          {result["city"]}, {result["province"]}{" "}
                          {result["postal_code"]}
                          
                          <br /><br />
                          
                          {result["phone_number"]}<br/>
                          {result["email"]}
                        </td>

                        <td className="cart-quantity">
                          ${result["bid_amount"]}
                        </td>

                        <td className="cart-quantity">
                          {result["hasPaid"] === 1 ? <span style={{color:"#ababab"}}>Paid</span> : <span style={{color:"red"}}>Not Paid</span>}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default AuctionResults;