const pledgeCategories = [
    {
        "ID": 31,
        "name": "$50K",
        "amount": 50000.0
    },
    {
        "ID": 32,
        "name": "$25K",
        "amount": 25000.0
    },
    {
        "ID": 33,
        "name": "$20K",
        "amount": 20000.0
    },
    {
        "ID": 34,
        "name": "$15K",
        "amount": 15000.0
    },
    {
        "ID": 35,
        "name": "$12.5K",
        "amount": 12500.0
    },
    {
        "ID": 36,
        "name": "$10K",
        "amount": 10000.0
    },
    {
        "ID": 37,
        "name": "$7.5K",
        "amount": 7500.0
    },
    {
        "ID": 38,
        "name": "$5K",
        "amount": 5000.0
    },
    {
        "ID": 39,
        "name": "$2.5K",
        "amount": 2500.0
    },
    {
        "ID": 40,
        "name": "$2K",
        "amount": 2000.0
    }
];

const auctionItems = [
    {
      "ID": 101,
      "OfferingType": "Wine",
      "ListingName": "Sunset Hills Vineyard 2019 Reserve Cabernet Sauvignon",
      "ListingNumber": "401",
      "MinimumBid": 120.0,
      "MinimumBiddingIncrement": 15.0,
      "MonetaryValue": 180.0,
      "TitleOfMonetaryValue": "Retail Value",
      "ItemDescription": "<p>Enjoy the rich flavors of Sunset Hills Vineyard's 2019 Reserve Cabernet Sauvignon, beautifully presented in an elegant wooden box. This exquisite vintage boasts notes of blackberry, cassis, and a hint of cedar, complemented by a velvety smooth texture that lingers on the palate.</p>",
      "Tags": ["27"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 250.0,
      "Image1": "401_Sunset-Hills-2019-Reserve-Cabernet-Sauvignon.png",
      "Image2": "401_Sunset-Hills-Logo.JPG",
      "PickupInstructions": "<p><em>All auction items will be available for pickup at Sunset Hills Vineyard on May 1st, from 11 AM to 5 PM. Please bring your confirmation email for pickup.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 302,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Jones Family",
      "isLive": 1,
      "ExternalURL": "https://www.sunsethillsvineyard.com/reserve-cabernet-sauvignon-2019",
      "HasBeenPurchased": 0
    },
    {
      "ID": 102,
      "OfferingType": "Artwork",
      "ListingName": "Abstract Expressionism Canvas Print",
      "ListingNumber": "402",
      "MinimumBid": 80.0,
      "MinimumBiddingIncrement": 10.0,
      "MonetaryValue": 150.0,
      "TitleOfMonetaryValue": "Estimated Value",
      "ItemDescription": "<p>Enhance your space with this vibrant abstract expressionism canvas print, featuring bold strokes and vivid colors that evoke a sense of energy and creativity. Perfect for art enthusiasts looking to add a contemporary touch to their collection.</p>",
      "Tags": ["15", "28"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 200.0,
      "Image1": "402_Abstract-Expressionism-Canvas-Print.png",
      "Image2": "402_Abstract-Expressionism-Logo.JPG",
      "PickupInstructions": "<p><em>Artwork can be collected from the auction venue on April 30th, between 12 PM and 6 PM. Please bring your receipt for verification.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 303,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Smith Gallery",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/abstract-expressionism-canvas-print",
      "HasBeenPurchased": 0
    },
    {
      "ID": 103,
      "OfferingType": "Gift Basket",
      "ListingName": "Gourmet Treats Delight Basket",
      "ListingNumber": "403",
      "MinimumBid": 50.0,
      "MinimumBiddingIncrement": 5.0,
      "MonetaryValue": 100.0,
      "TitleOfMonetaryValue": "Estimated Retail Value",
      "ItemDescription": "<p>Indulge in the finest gourmet treats with this deluxe gift basket, featuring a selection of artisan chocolates, premium wines, and savory snacks. Perfect for any occasion, guaranteed to delight your senses.</p>",
      "Tags": ["12", "35"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 150.0,
      "Image1": "403_Gourmet-Treats-Delight-Basket.png",
      "Image2": "403_Gourmet-Treats-Logo.JPG",
      "PickupInstructions": "<p><em>The gift basket can be picked up from the auction premises on May 2nd, from 10 AM to 4 PM. Please present your confirmation email upon collection.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 304,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Gourmet Delights Inc.",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/gourmet-treats-delight-basket",
      "HasBeenPurchased": 0
    },
    {
      "ID": 104,
      "OfferingType": "Travel Package",
      "ListingName": "Luxury Spa Retreat Weekend",
      "ListingNumber": "404",
      "MinimumBid": 300.0,
      "MinimumBiddingIncrement": 20.0,
      "MonetaryValue": 600.0,
      "TitleOfMonetaryValue": "Estimated Value",
      "ItemDescription": "<p>Escape to a luxurious spa retreat for a rejuvenating weekend getaway. Includes accommodation in a deluxe suite, gourmet dining experiences, and spa treatments to pamper your senses.</p>",
      "Tags": ["8", "42"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 800.0,
      "Image1": "404_Luxury-Spa-Retreat-Weekend.png",
      "Image2": "404_Luxury-Spa-Logo.JPG",
      "PickupInstructions": "<p><em>The travel package details will be sent to the winner via email. Please ensure to confirm your booking dates with our travel coordinator.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 305,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Serene Escapes Travel Agency",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/luxury-spa-retreat-weekend",
      "HasBeenPurchased": 0
    },
    {
      "ID": 105,
      "OfferingType": "Electronics",
      "ListingName": "Smart Home Automation Kit",
      "ListingNumber": "405",
      "MinimumBid": 150.0,
      "MinimumBiddingIncrement": 10.0,
      "MonetaryValue": 300.0,
      "TitleOfMonetaryValue": "Estimated Retail Value",
      "ItemDescription": "<p>Transform your home into a smart oasis with this comprehensive home automation kit. Includes smart lights, security cameras, and voice-controlled assistants for seamless integration and enhanced convenience.</p>",
      "Tags": ["14", "37"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 400.0,
      "Image1": "405_Smart-Home-Automation-Kit.png",
      "Image2": "405_Smart-Home-Logo.JPG",
      "PickupInstructions": "<p><em>The electronics package will be shipped to the winner's address within 5 business days of auction closing. Please provide accurate shipping details.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 306,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Smart Solutions Inc.",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/smart-home-automation-kit",
      "HasBeenPurchased": 0
    },
    {
      "ID": 106,
      "OfferingType": "Sports Memorabilia",
      "ListingName": "Signed Basketball by NBA Legends",
      "ListingNumber": "406",
      "MinimumBid": 200.0,
      "MinimumBiddingIncrement": 25.0,
      "MonetaryValue": 400.0,
      "TitleOfMonetaryValue": "Appraised Value",
      "ItemDescription": "<p>Add a piece of history to your collection with this signed basketball by NBA legends. Authenticated signatures of iconic players make this a cherished item for basketball enthusiasts.</p>",
      "Tags": ["19", "24"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 600.0,
      "Image1": "406_Signed-Basketball.png",
      "Image2": "406_Sports-Memorabilia-Logo.JPG",
      "PickupInstructions": "<p><em>The sports memorabilia will be available for pickup at the event venue on May 3rd, from 1 PM to 7 PM. Please bring your auction confirmation for verification.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 307,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Legends Sports Collectibles",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/signed-basketball-nba-legends",
      "HasBeenPurchased": 0
    },
    {
      "ID": 107,
      "OfferingType": "Fashion",
      "ListingName": "Luxury Designer Watch",
      "ListingNumber": "407",
      "MinimumBid": 180.0,
      "MinimumBiddingIncrement": 20.0,
      "MonetaryValue": 350.0,
      "TitleOfMonetaryValue": "Retail Appraisal Value",
      "ItemDescription": "<p>Elevate your style with this exquisite luxury designer watch, featuring precision craftsmanship and timeless elegance. A statement piece for discerning individuals who appreciate fine horology.</p>",
      "Tags": ["7", "31"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 500.0,
      "Image1": "407_Luxury-Designer-Watch.png",
      "Image2": "407_Designer-Watch-Logo.JPG",
      "PickupInstructions": "<p><em>The luxury watch will be shipped to the winner's address within 3 business days of auction closure. Please provide accurate shipping information.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 308,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Timeless Luxury Boutique",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/luxury-designer-watch",
      "HasBeenPurchased": 0
    },
    {
      "ID": 108,
      "OfferingType": "Home Decor",
      "ListingName": "Handcrafted Ceramic Vase Set",
      "ListingNumber": "408",
      "MinimumBid": 60.0,
      "MinimumBiddingIncrement": 8.0,
      "MonetaryValue": 120.0,
      "TitleOfMonetaryValue": "Estimated Retail Value",
      "ItemDescription": "<p>Add a touch of sophistication to your home with this handcrafted ceramic vase set. Featuring intricate designs and elegant finishes, perfect for showcasing your floral arrangements.</p>",
      "Tags": ["11", "36"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 150.0,
      "Image1": "408_Handcrafted-Ceramic-Vase-Set.png",
      "Image2": "408_Ceramic-Vase-Logo.JPG",
      "PickupInstructions": "<p><em>The ceramic vase set can be collected from the auction venue on May 4th, from 9 AM to 2 PM. Please present your confirmation email for verification.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 309,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Artisan Creations Ltd.",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/handcrafted-ceramic-vase-set",
      "HasBeenPurchased": 0
    },
    {
      "ID": 109,
      "OfferingType": "Books",
      "ListingName": "Rare First Edition Book Collection",
      "ListingNumber": "409",
      "MinimumBid": 250.0,
      "MinimumBiddingIncrement": 30.0,
      "MonetaryValue": 500.0,
      "TitleOfMonetaryValue": "Appraised Value",
      "ItemDescription": "<p>Discover literary treasures with this rare collection of first edition books, showcasing classic titles and historical significance. A must-have for book collectors and enthusiasts.</p>",
      "Tags": ["5", "18"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 700.0,
      "Image1": "409_Rare-First-Edition-Books.png",
      "Image2": "409_Book-Collection-Logo.JPG",
      "PickupInstructions": "<p><em>The rare book collection will be shipped to the winner's address within 7 business days of auction conclusion. Please provide accurate shipping details.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 310,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Literary Antiquities Ltd.",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/rare-first-edition-book-collection",
      "HasBeenPurchased": 0
    },
    {
      "ID": 110,
      "OfferingType": "Outdoor Gear",
      "ListingName": "Camping Adventure Essentials Kit",
      "ListingNumber": "410",
      "MinimumBid": 100.0,
      "MinimumBiddingIncrement": 12.0,
      "MonetaryValue": 200.0,
      "TitleOfMonetaryValue": "Estimated Retail Value",
      "ItemDescription": "<p>Embark on outdoor adventures with confidence using this comprehensive camping essentials kit. Includes durable tent, sleeping bags, portable stove, and more for an unforgettable camping experience.</p>",
      "Tags": ["9", "22"],
      "SendTaxReceipt": "Yes",
      "BuyNowPrice": 250.0,
      "Image1": "410_Camping-Adventure-Essentials-Kit.png",
      "Image2": "410_Outdoor-Gear-Logo.JPG",
      "PickupInstructions": "<p><em>The camping gear will be shipped to the winner's address within 5 business days of auction closing. Please provide accurate shipping details.</em></p>",
      "AdditionalFees": 0.0,
      "Order": 311,
      "isDeleted": 0,
      "Image3": "",
      "Image4": "",
      "Image5": "",
      "Image6": null,
      "Image7": null,
      "Image8": null,
      "DonorName": "Adventure Outfitters Ltd.",
      "isLive": 1,
      "ExternalURL": "https://www.example.com/camping-adventure-essentials-kit",
      "HasBeenPurchased": 0
    }
  ]
  

export const mockData = {pledgeCategories, auctionItems};