import React, { Component } from "react";
import "../css/Wine.css"; // Create a separate CSS file for styling
import LogoHeader from "./CheckinLogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Fuse from "fuse.js";

class Usher extends Component {
  constructor(props) {
    super(props);

    const categories = [
      { name: "All", value: "All" },
      { name: "Already Checked In", value: "CheckedIn" },
      { name: "Not Yet Checked In", value: "NotCheckedIn" },
    ];

    this.state = {
      wines: [],
      attendees: [],
      attendeeError: "",
      newWine: {
        Identifier: "",
        "Cork Number": "",
      },
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0,
      categories: categories,
      filterOption: "All",
      search: "",
    };
  }

  fetchAttendees = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/attendees`
      );
      let data = await response.json();
      data = data.filter((attendee) => attendee.attendee_id !== 328);
      this.setState({ attendees: data, isLoading: false });
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  };

  handleFilterChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ filterOption: selectedValue });

    console.log(selectedValue);
  };

  async componentDidMount() {
    this.fetchAttendees();

    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  hasEmptyFields(wine) {
    for (const key in wine) {
      if (wine[key] === "") {
        if (key === "Identifier" || key === "Cork Number") {
          return true; // Found an empty field
        }
      }
    }
    return false; // No empty fields found
  }

  toCamelCaseName = (inputName) => {
    if (typeof inputName === "string" && inputName.length > 0) {
      // Split the input name into words using spaces
      const words = inputName.split(" ");

      // Capitalize the first letter of each word and convert the rest to lowercase
      const camelCaseName = words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

      return camelCaseName;
    } else {
      return ""; // or handle the case of an empty or undefined inputName as needed
    }
  };

  handleIdentifierChange = (e) => {
    this.setState({
      newWine: { ...this.state.newWine, Identifier: e.target.value },
    });
  };


  clearSearch = async(e) => {
    e.preventDefault();

    this.setState({
      search: "",
      filterOption: "All"
    })
  }


  handlePickup = async (event, wineId) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/brenda/wines`;

    const requestBody = {
      ID: wineId,
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Wine picked up successfully");
      await this.fetchWines();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error picking up wine", error);
    }
  };

  handleSearchChange = async (e) => {
    this.setState({ search: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Filtered Attendees List</span>
            </h1>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Contact</td>
                    <td className="cart-quantity">Is Checkin Complete?</td>
                    <td className="cart-quantity">Seat / Section Assignment</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.attendees
                    .slice()
                    .filter((attendee) => {
                      if (this.state.filterOption === "CheckedIn") {
                        return attendee.checkin_time !== null;
                      } else if (this.state.filterOption === "NotCheckedIn") {
                        return attendee.checkin_time === null;
                      }

                      // Fuzzy search logic
                      if (this.state.search) {
                        const fuse = new Fuse([attendee], {
                          keys: ["attendee_firstname", "attendee_lastname"], // Define the keys to search
                          includeScore: true, // Include score to prioritize results
                          threshold: 0.4, // Set a threshold for fuzzy search
                        });
                        const results = fuse.search(this.state.search);
                        return results.length > 0; // Return true if any matching result is found
                      }

                      return false; // Return all attendees if the filter option is "All"
                    })
                    .sort((a, b) => {
                      // First, sort by checked-in status
                      if (b.checkin_time !== null && a.checkin_time === null) {
                        return 1; // b comes before a if b is checked in and a is not
                      } else if (
                        a.checkin_time !== null &&
                        b.checkin_time === null
                      ) {
                        return -1; // a comes before b if a is checked in and b is not
                      }
                      // If both are checked in or not checked in, sort by attendee_id
                      return b.attendee_id - a.attendee_id;
                    })
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {result["attendee_firstname"]}{" "}
                          {result["attendee_lastname"]}{" "}
                          {result["isPrimary"] === 1 && (
                            <span style={{ color: "#fcb61c" }}>[PRIMARY]</span>
                          )}
                          <br />
                          <br />
                          {result["address_line_1"]}
                          <br />
                          {result["address_line_2"] && (
                            <>
                              {result["address_line_2"]}
                              <br />
                            </>
                          )}
                          {result["city"]}, {result["province"]}{" "}
                          {result["postal_code"]}
                          <br />
                          {result["attendee_phone_number"]
                            ? result["attendee_phone_number"]
                            : result["registration_phone_number"]}
                          <br />
                          {result["email"]}
                        </td>
                        <td className="cart-quantity">
                          {result["checkin_time"] !== null ? (
                            <span style={{ color: "green" }}>Checked in</span>
                          ) : (
                            <span style={{ color: "red" }}>Not checked in</span>
                          )}
                        </td>
                        <td className="cart-quantity">
                          Seat: TBD
                          <br />
                          Section: TBD
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>


            <h1 className="main-ttl">
              <span>Search Attendee</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Status Filter</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.filterOption}
                        onChange={this.handleFilterChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p>
                    <label htmlFor="keywordSearch">Name Search</label>
                    <input
                      type="text"
                      id="keywordSearch"
                      name="keywordSearch"
                      value={this.state.search}
                      onChange={this.handleSearchChange}
                      placeholder="Name Search"
                    />
                  </p>
                  <p>
                    <button
                      onClick={this.clearSearch}
                      disabled={this.state.isLoading}
                    >
                      Clear Search
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Attendees List</span>
            </h1>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Contact</td>
                    <td className="cart-quantity">Is Checkin Complete?</td>
                    <td className="cart-quantity">Seat / Section Assignment</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.attendees
                    .slice()
                    .sort((a, b) => {
                      // First, sort by checked-in status
                      if (b.checkin_time !== null && a.checkin_time === null) {
                        return 1; // b comes before a if b is checked in and a is not
                      } else if (
                        a.checkin_time !== null &&
                        b.checkin_time === null
                      ) {
                        return -1; // a comes before b if a is checked in and b is not
                      }
                      // If both are checked in or not checked in, sort by attendee_id
                      return b.attendee_id - a.attendee_id;
                    })
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {result["attendee_firstname"]}{" "}
                          {result["attendee_lastname"]}{" "}
                          {result["isPrimary"] === 1 && (
                            <span style={{ color: "#fcb61c" }}>[PRIMARY]</span>
                          )}
                          <br />
                          <br />
                          {result["address_line_1"]}
                          <br />
                          {result["address_line_2"] && (
                            <>
                              {result["address_line_2"]}
                              <br />
                            </>
                          )}
                          {result["city"]}, {result["province"]}{" "}
                          {result["postal_code"]}
                          <br />
                          {result["attendee_phone_number"]
                            ? result["attendee_phone_number"]
                            : result["registration_phone_number"]}
                          <br />
                          {result["email"]}
                        </td>
                        <td className="cart-quantity">
                          {result["checkin_time"] !== null ? (
                            <span style={{ color: "green" }}>Checked in</span>
                          ) : (
                            <span style={{ color: "red" }}>Not checked in</span>
                          )}
                        </td>
                        <td className="cart-quantity">
                          Seat: TBD
                          <br />
                          Section: TBD
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Usher;