import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Add Quill styles
import TagsField2 from "./TagsField2";

class EditAuctionItemForm extends Component {
  render() {
    const {
      editedItem,
      error,
      isLoading,
      updateItem,
      handleItemUpdate,
      MonetaryValueTitles,
      handleEditorChange,
      SingleChoices,
      handleFileUpdate,
      handleTagUpdate,
      toast,
      existingTags,
      fetchExistingTags,
      handleDeleteImage
    } = this.props;

    const editorStyle = {
      height: "100px", // Adjust this value to increase the height
    };

    return (
      <>
        <h1 className="main-ttl">
          <span>Edit an Auction Item</span>
        </h1>
        <div className="auth-wrap">
          <div className="auth-col">
            <form onSubmit={updateItem} className="login">
              <p>
                <label htmlFor="ExternalURL">External URL</label>
                <input
                  type="text"
                  id="ExternalURL"
                  name="ExternalURL"
                  value={editedItem.ExternalURL}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="ListingName">
                  Listing Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="ListingName"
                  name="ListingName"
                  value={editedItem.ListingName}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="DonorName">
                  Donor Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="DonorName"
                  name="DonorName"
                  value={editedItem.DonorName}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="ListingNumber">
                  Listing Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="ListingNumber"
                  name="ListingNumber"
                  value={editedItem.ListingNumber}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="MinimumBid">
                  Minimum Bid <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MinimumBid"
                    name="MinimumBid"
                    value={editedItem.MinimumBid}
                    min={1} // Minimum value set to $1
                    step={1} // Increment by $1
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p>
                <label htmlFor="MinimumBiddingIncrement">
                  Minimum Bidding Increment <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MinimumBiddingIncrement"
                    name="MinimumBiddingIncrement"
                    value={editedItem.MinimumBiddingIncrement}
                    min={1} // Minimum value set to $1
                    step={1} // Increment by $1
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p>
                <label htmlFor="MonetaryValue">
                  Monetary Value <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MonetaryValue"
                    name="MonetaryValue"
                    value={editedItem.MonetaryValue}
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p className="contactform-field contactform-text">
                <label className="contactform-label">
                  Title of Monetary Value
                </label>
                <span className="contactform-input">
                  <Form.Control
                    id="TitleOfMonetaryValue"
                    as="select"
                    value={editedItem.TitleOfMonetaryValue}
                    onChange={handleItemUpdate}
                  >
                    <option value="" disabled hidden style={{ color: "#999" }}>
                      &#x2015; Choose an option &#x2015;
                    </option>
                    {MonetaryValueTitles.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </p>

              <p style={{ paddingBottom: "50px" }}>
                <label
                  htmlFor="ItemDescription"
                  style={{ marginBottom: "20px" }}
                >
                  Item Description <span className="required">*</span>
                </label>
                <span className="contactform-input">
                  <ReactQuill
                    style={editorStyle}
                    value={editedItem.ItemDescription}
                    onChange={(content) =>
                      handleEditorChange(content, "ItemDescription")
                    }
                  />
                </span>
              </p>

              <TagsField2
                newItem={editedItem}
                handleTagUpdate={handleTagUpdate}
                toast={toast}
                existingTags={existingTags}
                fetchExistingTags={fetchExistingTags}
              />

              <p className="contactform-field contactform-text">
                <label className="contactform-label">
                  Send tax receipt if sale price is 125%+ of value
                </label>
                <span className="contactform-input">
                  <Form.Control
                    id="SendTaxReceipt"
                    as="select"
                    value={editedItem.SendTaxReceipt}
                    onChange={handleItemUpdate}
                  >
                    {SingleChoices.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </p>
              <p>
                <label htmlFor="BuyNowPrice">
                  Buy Now Option Purchase Price
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="BuyNowPrice"
                    name="BuyNowPrice"
                    value={editedItem.BuyNowPrice}
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>

              {/* Image inputs */}
              {[...Array(8)].map((_, index) => (
                <React.Fragment key={index}>
                  <p style={{ textAlign: "left" }}>
                    Update Image {index + 1}
                    {editedItem[`Image${index + 1}`] && (
                      <>
                        :{" "}
                        <span style={{ fontWeight: "900" }}>
                          {editedItem[`Image${index + 1}`]}
                        </span>
                      </>
                    )}
                          {/* Delete button */}
                          {editedItem[`Image${index + 1}`] && (
                            <button
                              onClick={(e) => handleDeleteImage(e, `Image${index + 1}`)} // Add a function to handle image deletion
                            >
                              Delete
                            </button>
                          )}
                  </p>
                  <p>
                    <input
                      type="file"
                      id={`Image${index + 1}`}
                      name={`Image${index + 1}`}
                      accept="image/*"
                      onChange={handleFileUpdate}
                    />
                  </p>
                  {index < 7 && (
                    <hr
                      style={{
                        borderTop: "2px solid #ccc", // Change the color here
                        margin: "20px 0", // Adjust spacing as needed
                      }}
                    />
                  )}{" "}
                  {/* Add dividing line except for the last image input */}
                </React.Fragment>
              ))}

              <p style={{ paddingBottom: "50px" }}>
                <label
                  htmlFor="PickupInstructions"
                  style={{ marginBottom: "20px" }}
                >
                  Pickup Instructions <span className="required">*</span>
                </label>
                <span className="contactform-input">
                  <ReactQuill
                    style={editorStyle}
                    value={editedItem.PickupInstructions}
                    onChange={(content) =>
                      handleEditorChange(content, "PickupInstructions")
                    }
                  />
                </span>
              </p>

              <p className="auth-submit">
                <input type="submit" value="Update Item" disabled={isLoading} />
              </p>
            </form>

            {error && (
              <div className="err404">
                <p className="err404-search">{error}</p>
              </div>
            )}
          </div>
          <div className="auth-col"></div>
        </div>
      </>
    );
  }
}

export default EditAuctionItemForm;