import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Add Quill styles
import TagsField2 from "./TagsField2";

class AuctionItemForm extends Component {
  render() {
    const {
      newItem,
      error,
      isLoading,
      addItem,
      handleItemUpdate,
      MonetaryValueTitles,
      handleEditorChange,
      SingleChoices,
      handleFileUpdate,
      handleTagUpdate,
      toast,
      existingTags,
      fetchExistingTags,
    } = this.props;

    const editorStyle = {
      height: "100px", // Adjust this value to increase the height
    };

    return (
      <section className="container stylization maincont">
        <h1 className="main-ttl">
          <span>Add an Auction Item</span>
        </h1>
        <div className="auth-wrap">
          <div className="auth-col">
            <form onSubmit={addItem} className="login">
            <p>
                <label htmlFor="ExternalURL">
                  External URL
                </label>
                <input
                  type="text"
                  id="ExternalURL"
                  name="ExternalURL"
                  value={newItem.ExternalURL}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="ListingName">
                  Listing Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="ListingName"
                  name="ListingName"
                  value={newItem.ListingName}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="DonorName">
                  Donor Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="DonorName"
                  name="DonorName"
                  value={newItem.DonorName}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="ListingNumber">
                  Listing Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="ListingNumber"
                  name="ListingNumber"
                  value={newItem.ListingNumber}
                  onChange={handleItemUpdate}
                />
              </p>
              <p>
                <label htmlFor="MinimumBid">
                  Minimum Bid <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MinimumBid"
                    name="MinimumBid"
                    value={newItem.MinimumBid}
                    min={1} // Minimum value set to $1
                    step={1} // Increment by $1
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p>
                <label htmlFor="MinimumBiddingIncrement">
                  Minimum Bidding Increment <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MinimumBiddingIncrement"
                    name="MinimumBiddingIncrement"
                    value={newItem.MinimumBiddingIncrement}
                    min={1} // Minimum value set to $1
                    step={1} // Increment by $1
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p>
                <label htmlFor="MonetaryValue">
                  Monetary Value <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="MonetaryValue"
                    name="MonetaryValue"
                    value={newItem.MonetaryValue}
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p className="contactform-field contactform-text">
                <label className="contactform-label">
                  Title of Monetary Value
                </label>
                <span className="contactform-input">
                  <Form.Control
                    id="TitleOfMonetaryValue"
                    as="select"
                    value={newItem.TitleOfMonetaryValue}
                    onChange={handleItemUpdate}
                  >
                    <option value="" disabled hidden style={{ color: "#999" }}>
                      &#x2015; Choose an option &#x2015;
                    </option>
                    {MonetaryValueTitles.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </p>

              <p style={{ paddingBottom: "50px" }}>
                <label
                  htmlFor="ItemDescription"
                  style={{ marginBottom: "20px" }}
                >
                  Item Description <span className="required">*</span>
                </label>
                <span className="contactform-input">
                  <ReactQuill
                    style={editorStyle}
                    value={newItem.ItemDescription}
                    onChange={(content) =>
                      handleEditorChange(content, "ItemDescription")
                    }
                  />
                </span>
              </p>

              <TagsField2
                newItem={newItem}
                handleTagUpdate={handleTagUpdate}
                toast={toast}
                existingTags={existingTags}
                fetchExistingTags={fetchExistingTags}
              />

              <p className="contactform-field contactform-text">
                <label className="contactform-label">
                  Send tax receipt if sale price is 125%+ of value
                </label>
                <span className="contactform-input">
                  <Form.Control
                    id="SendTaxReceipt"
                    as="select"
                    value={newItem.SendTaxReceipt}
                    onChange={handleItemUpdate}
                  >
                    {SingleChoices.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </p>
              <p>
                <label htmlFor="BuyNowPrice">
                  Buy Now Option Purchase Price
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="BuyNowPrice"
                    name="BuyNowPrice"
                    value={newItem.BuyNowPrice}
                    onChange={handleItemUpdate}
                  />
                  <span className="input-group-addon">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span>
                </div>
              </p>
              <p>
                <label htmlFor="images">
                  Image 1 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image1"
                  name="Image1"
                  accept="image/*"
                  multiple
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 2 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image2"
                  name="Image2"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 3 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image3"
                  name="Image3"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              {/* Repeat this pattern for images 4 through 8 */}
              <p>
                <label htmlFor="images">
                  Image 4 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image4"
                  name="Image4"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 5 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image5"
                  name="Image5"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 6 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image6"
                  name="Image6"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 7 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image7"
                  name="Image7"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>
              <p>
                <label htmlFor="images">
                  Image 8 [Aspect ratio{" "}
                  <span style={{ fontWeight: "900" }}>5:3</span>, at least{" "}
                  <span style={{ fontWeight: "900" }}>537px x 322px</span>]
                </label>
                <input
                  type="file"
                  id="Image8"
                  name="Image8"
                  accept="image/*"
                  onChange={handleFileUpdate}
                />
              </p>

              <p style={{ paddingBottom: "50px" }}>
                <label
                  htmlFor="PickupInstructions"
                  style={{ marginBottom: "20px" }}
                >
                  Pickup Instructions <span className="required">*</span>
                </label>
                <span className="contactform-input">
                  <ReactQuill
                    style={editorStyle}
                    value={newItem.PickupInstructions}
                    onChange={(content) =>
                      handleEditorChange(content, "PickupInstructions")
                    }
                  />
                </span>
              </p>

              <p className="auth-submit">
                <input type="submit" value="Add Item" disabled={isLoading} />
              </p>
            </form>

            {error && (
              <div className="err404">
                <p className="err404-search">{error}</p>
              </div>
            )}
          </div>
          <div className="auth-col"></div>
        </div>
      </section>
    );
  }
}

export default AuctionItemForm;