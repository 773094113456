import React, { Component } from "react";
import "../css/Wine.css"; // Create a separate CSS file for styling
import CheckinLogoHeader from "./CheckinLogoHeader";
import Spinner from "../Spinner";
import axios from "axios";

class Checkin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wines: [],
      attendees: [],
      attendeeError: "",
      checkinAttendee: {
        Identifier: "",
      },
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0,
      ticketNumberInput: React.createRef(),
      tableRef: React.createRef()
    };

  }

  scrollToTable = () => {
    if (this.state.tableRef.current) {
      this.state.tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  fetchAttendees = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/attendees`
      );
      let data = await response.json();
      data = data.filter((attendee) => attendee.attendee_id !== 328);
      return data;
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  };

  async componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  hasEmptyFields(wine) {
    for (const key in wine) {
      if (wine[key] === "") {
        if (key === "Identifier" || key === "Cork Number") {
          return true; // Found an empty field
        }
      }
    }
    return false; // No empty fields found
  }

  toCamelCaseName = (inputName) => {
    if (typeof inputName === "string" && inputName.length > 0) {
      // Split the input name into words using spaces
      const words = inputName.split(" ");

      // Capitalize the first letter of each word and convert the rest to lowercase
      const camelCaseName = words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

      return camelCaseName;
    } else {
      return ""; // or handle the case of an empty or undefined inputName as needed
    }
  };

  handleIdentifierChange = async (e) => {
    this.setState({
      checkinAttendee: {
        ...this.state.checkinAttendee,
        Identifier: e.target.value,
      },
    });
  };

  retrieveSingleAttendee = async () => {
    const attendees = await this.fetchAttendees();

    console.log(attendees);

    const existingAttendee = attendees.find(
      (attendee) =>
        attendee["barcode"].toLowerCase() === this.state.checkinAttendee.Identifier.toLowerCase()
    );

    return existingAttendee;
  };

  checkinAttendee = async () => {
    let existingAttendee = await this.retrieveSingleAttendee();

    if (!existingAttendee) {
      return;
    }

    if (existingAttendee.checkin_id) {
      this.setState({ attendee: existingAttendee, checkinAttendee: {
        Identifier: "",
      } }, ()=> this.scrollToTable());
      return;
    }

    try {
      const body = {
        attendee_id: existingAttendee["attendee_id"],
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/checkin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        existingAttendee = await this.retrieveSingleAttendee();

        this.setState({
          checkinAttendee: {
            Identifier: "",
          },
          attendee: existingAttendee,
        }, () => {
          console.log("SCREAM");
          this.scrollToTable();
        });
      } else {
        this.setState({ error: "Failed to check in attendee." });
      }
    } catch (error) {
      this.setState({ error: `Error checking in attendee: ${error.message}` });
      console.error("Error checking in attendee:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState({ isLoading: true });

      // First, search for the attendee.
      await this.checkinAttendee();

      this.setState({ error: "" });

      this.setState({ isLoading: false });
    }
  };

nextAttendee = async (e) => {
    e.preventDefault();
    // Your nextAttendee function logic
    // Set focus back to Ticket Number input field
    this.state.ticketNumberInput.current.focus();
    this.setState({attendee: null, });
  };

  updateCheckinStatus = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    try {
      const checkin_id = this.state.attendee["checkin_id"];

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/checkin?checkin_id=${checkin_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const existingAttendee = await this.retrieveSingleAttendee();

        this.setState({
          checkinAttendee: {
            Identifier: "",
          },
          attendee: existingAttendee,
        });
      } else {
        this.setState({ error: "Failed to revert check in attendee." });
      }
    } catch (error) {
      this.setState({ error: `Error checking in attendee: ${error.message}` });
      console.error("Error checking in attendee:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    return (
      <>
        <CheckinLogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Checkin Attendee</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form className="login">
                  <p>
                    <label htmlFor="identifier">
                      Ticket Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="identifier"
                      name="Identifier"
                      value={this.state.checkinAttendee.Identifier}
                      onChange={this.handleIdentifierChange}
                      placeholder="Ticket Number"
                      onKeyDown={this.handleKeyPress}
                      autoFocus
                      ref={this.state.ticketNumberInput} // Assign ref to input field
                      style={{ WebkitTextSizeAdjust: '100%', 'fontSize': "16px" }} // Apply the style here
                    />
                  </p>

                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col">
  {this.state.attendee && (
    <table className="attendee-table" ref={this.state.tableRef}>
      <tbody>
        <tr>
          <td className="info-label">Name:</td>
          <td className="info-value">
            {this.state.attendee.attendee_firstname}{" "}
            {this.state.attendee.attendee_lastname}
          </td>
        </tr>
        <tr>
          <td className="info-label">Phone Number:</td>
          <td className="info-value">{this.state.attendee["attendee_phone_number"]}</td>
        </tr>
        <tr>
          <td className="info-label">Email:</td>
          <td className="info-value">{this.state.attendee.email}</td>
        </tr>
        <tr>
          <td className="info-label">Seat Number:</td>
          <td className="info-value">TBD</td>
        </tr>
        <tr>
          <td className="info-label">Table Number:</td>
          <td className="info-value">TBD</td>
        </tr>
        <tr>
          <td className="info-label">Status:</td>
          <td className="info-value">
            {this.state.attendee["checkin_time"] !== null ? (
              <span style={{ color: "green", fontSize: "24px" }}>Checked in</span>
            ) : (
              <span style={{ color: "red", fontSize: "24px" }}>Not checked in</span>
            )}
          </td>
        </tr>
        {/* Additional rows */}
      </tbody>
    </table>
  )}

  {this.state.attendeeError && (
    <div className="err404">
      <p className="err404-search">{this.state.attendeeError}</p>
    </div>
  )}
</div>

            </div>
          </section>

          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Checkin;