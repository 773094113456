import React from "react";
import logo from "../img/lockup.png"

function CheckinLogoHeader() {

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo grapejuice">
          <a href="/">
            <img src={logo} alt="Aura Demo" />
          </a>
        </div>
       
      </div>
    </div>
    </header>
  );
}

export default CheckinLogoHeader;