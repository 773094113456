const all = [
    {
        "attendee_id": 328,
        "purchase_reference": "pi_3Om6VVEpNJnM0fmt1r6k3cU6",
        "attendee_firstname": "John",
        "attendee_lastname": "Doe",
        "email": "john.doe@example.com",
        "phone_number": "555-1234",
        "address_line_1": "1234 Elm Street",
        "address_line_2": "",
        "city": "Springfield",
        "province": "IL",
        "postal_code": "62701",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": null,
        "barcode": "ABCDE12345",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table1"
    },
    {
        "attendee_id": 329,
        "purchase_reference": "pi_3OmK2SEpNJnM0fmt0KnCw7SO",
        "attendee_firstname": "Jane",
        "attendee_lastname": "Smith",
        "email": "jane.smith@example.com",
        "phone_number": "555-5678",
        "address_line_1": "5678 Oak Street",
        "address_line_2": "",
        "city": "Shelbyville",
        "province": "IL",
        "postal_code": "62565",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": null,
        "barcode": "FGHIJ67890",
        "appleWalletStatus": "PASS_ISSUED",
        "table_name": "Table2"
    },
    {
        "attendee_id": 330,
        "purchase_reference": "pi_3OmK2SEpNJnM0fmt0KnCw7SO",
        "attendee_firstname": "Alice",
        "attendee_lastname": "Johnson",
        "email": "alice.johnson@example.com",
        "phone_number": "555-8765",
        "address_line_1": "8765 Pine Street",
        "address_line_2": null,
        "city": "Ogdenville",
        "province": "IL",
        "postal_code": "62025",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 0,
        "dietary": "Vegetarian",
        "barcode": "KLMNO54321",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table3"
    },
    {
        "attendee_id": 331,
        "purchase_reference": "pi_4Km7WEWpNJnN1fmt2s7k4dU8",
        "attendee_firstname": "Bob",
        "attendee_lastname": "Brown",
        "email": "bob.brown@example.com",
        "phone_number": "555-2345",
        "address_line_1": "2345 Maple Street",
        "address_line_2": "",
        "city": "Capital City",
        "province": "IL",
        "postal_code": "62702",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": "Gluten-Free",
        "barcode": "QRSTU98765",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table4"
    },
    {
        "attendee_id": 332,
        "purchase_reference": "pi_4Lm8XFYpNJnO2gmt3t8l5eV9",
        "attendee_firstname": "Carol",
        "attendee_lastname": "Davis",
        "email": "carol.davis@example.com",
        "phone_number": "555-3456",
        "address_line_1": "3456 Birch Street",
        "address_line_2": "",
        "city": "North Haverbrook",
        "province": "IL",
        "postal_code": "62564",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": "Vegan",
        "barcode": "UVWXY43210",
        "appleWalletStatus": "PASS_ISSUED",
        "table_name": "Table5"
    },
    {
        "attendee_id": 333,
        "purchase_reference": "pi_5Nm9YGZpNJnP3hnt4u9m6fW0",
        "attendee_firstname": "David",
        "attendee_lastname": "Martinez",
        "email": "david.martinez@example.com",
        "phone_number": "555-4567",
        "address_line_1": "4567 Cedar Street",
        "address_line_2": "",
        "city": "Springfield",
        "province": "IL",
        "postal_code": "62703",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": null,
        "barcode": "ZABCDE67890",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table6"
    },
    {
        "attendee_id": 334,
        "purchase_reference": "pi_6On0ZHZpNJnQ4iot5v0n7gX1",
        "attendee_firstname": "Emma",
        "attendee_lastname": "Lopez",
        "email": "emma.lopez@example.com",
        "phone_number": "555-5678",
        "address_line_1": "5678 Redwood Street",
        "address_line_2": "",
        "city": "Shelbyville",
        "province": "IL",
        "postal_code": "62565",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": "Nut-Free",
        "barcode": "FGHIJ54321",
        "appleWalletStatus": "PASS_ISSUED",
        "table_name": "Table7"
    },
    {
        "attendee_id": 335,
        "purchase_reference": "pi_7Pp1AIApNJnR5jpt6w1o8hY2",
        "attendee_firstname": "Frank",
        "attendee_lastname": "Wilson",
        "email": "frank.wilson@example.com",
        "phone_number": "555-6789",
        "address_line_1": "6789 Spruce Street",
        "address_line_2": "",
        "city": "Ogdenville",
        "province": "IL",
        "postal_code": "62025",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": null,
        "barcode": "KLMNO43210",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table8"
    },
    {
        "attendee_id": 336,
        "purchase_reference": "pi_8Qq2BJBpNJnS6kqt7x2p9iZ3",
        "attendee_firstname": "Grace",
        "attendee_lastname": "Anderson",
        "email": "grace.anderson@example.com",
        "phone_number": "555-7890",
        "address_line_1": "7890 Chestnut Street",
        "address_line_2": "",
        "city": "Capital City",
        "province": "IL",
        "postal_code": "62702",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": "Kosher",
        "barcode": "PQRST67890",
        "appleWalletStatus": "PASS_INSTALLED",
        "table_name": "Table9"
    },
    {
        "attendee_id": 337,
        "purchase_reference": "pi_9Rr3CKCpNJnT7lru8y3q0jA4",
        "attendee_firstname": "Henry",
        "attendee_lastname": "Thomas",
        "email": "henry.thomas@example.com",
        "phone_number": "555-8901",
        "address_line_1": "8901 Aspen Street",
        "address_line_2": "",
        "city": "North Haverbrook",
        "province": "IL",
        "postal_code": "62564",
        "country": "USA",
        "isRegistrationComplete": 1,
        "isPrimary": 1,
        "dietary": "Halal",
        "barcode": "UVWXY54321",
        "appleWalletStatus": "PASS_ISSUED",
        "table_name": "Table10"
    }
];


export const mockData = {all};